import type { ApiCacheOptions } from '@shared/util/request'
import { MINUTE_IN_MS } from '@shared/util/date'
import { ApiOptions, getRequestWithCache } from '../index'
import { getPartner } from '../../router/index'

export interface UserModuleInfo {
  id: number
  type: number
  role: number
  organizationUserId: number
  organizationLogo: string
  photo: string
  globalUserName: string
  displayName: string
  userId: number
  phone: string
  userModuleTreeNodeUIDataList: Module[]
  navList: Module[]
  isOwner: boolean
}
export interface Action {
  action: string
  allowed: boolean
}
export interface Module {
  id: number
  name: string
  icon: string
  activeIcon: string
  children: Module[]
  actions: Action[]
}
export const getUserModuleInfo = (userRole: 'Teacher' | 'Student', cacheOptions: ApiCacheOptions = {}) => {
  const { partnerId } = getPartner()
  return getRequestWithCache<UserModuleInfo>(
    new ApiOptions({
      url: `/net/course/${partnerId}/${userRole}/user/info`,
    }),
    { expireTime: MINUTE_IN_MS, ...cacheOptions },
  )
}
