import { setToken } from '@shared/util/cookie'
import { ApiOptions, postRequest } from '../index'
import { refreshToken as refreshTokenApi } from './userLogin'

export const refreshToken = () => {
  return Promise.all([refreshTokenApi().then((res) => {
    if (res.data.token) {
      setToken(res.data.token)
    }
  })])
}

/**
 *
 * @param {Object} params
 * @param {String} params.phone
 * @param {String} params.password
 * @param {Object} params.validation
 * @param {String} params.validation.sessionId
 * @param {Integer} params.validation.type
 * @param {String} params.validation.userInput
 */
export const resetPassword = (args = {}) => {
  return postRequest(
    new ApiOptions({
      url: '/net/course/Open/Svc/ResetPassword',
      args: {
        ...args,
      },
    }),
  )
}
/**
 *
 * @param {Object} params
 * @param {String} params.displayName
 * @param {String} params.gender
 * @param {String} params.address
 * @param {String} params.photoImageUrl
 * @param {Object} params.studentStatusPhotoInfo
 * @param {Number} params.studentStatusPhotoInfo.schoolId
 * @param {String} params.studentStatusPhotoInfo.studentStatusPhotoUrl
 */
export const updateAccount = (args = {}) => {
  return postRequest(
    new ApiOptions({
      url: '/net/course/Account/Update',
      args: {
        ...args,
      },
    }),
  )
}
/**
 *
 * @param {Object} params
 * @param {String} params.identifyInfo
 * @param {Boolean} params.force
 * @param {Object} params.validation
 * @param {String} params.validation.sessionId
 * @param {Integer} params.validation.type
 * @param {String} params.validation.userInput
 * @param {Integer} params.identifyType
 */
export const bindIdentifyInfo = (args = {}) => {
  return postRequest(
    new ApiOptions({
      url: '/net/course/Account/BindIdentifyInfo',
      args: {
        ...args,
      },
    }),
  )
}
