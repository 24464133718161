import { getPromise } from './promise'
import { toCamelCase } from './string'

const wrapMap = {
  legend: {
    intro: '<fieldset>',
    outro: '</fieldset>',
  },
  area: {
    intro: '<map>',
    outro: '</map>',
  },
  param: {
    intro: '<object>',
    outro: '</object>',
  },
  thead: {
    intro: '<table>',
    outro: '</table>',
  },
  tr: {
    intro: '<table><tbody>',
    outro: '</tbody></table>',
  },
  col: {
    intro: '<table><tbody></tbody><colgroup>',
    outro: '</colgroup></table>',
  },
  td: {
    intro: '<table><tbody><tr>',
    outro: '</tr></tbody></table>',
  },
}
// elements needing a construct already defined by other elements
;['tbody', 'tfoot', 'colgroup', 'caption'].forEach((tag) => {
  wrapMap[tag] = wrapMap.thead
})
wrapMap.th = wrapMap.td

/**
 *
 * @param {String} HTMLString
 * @returns {Element}
 */
export function createDOM(HTMLString) {
  if (!HTMLString) {
    return
  }
  const tmp = document.createElement('div')
  const tag = /[\w:-]+/.exec(HTMLString)[0]
  const inMap = wrapMap[tag]
  let validHTML = HTMLString.trim()
  if (inMap) {
    validHTML = inMap.intro + validHTML + inMap.outro
  }
  tmp.insertAdjacentHTML('afterbegin', validHTML)
  let node = tmp.lastChild
  if (inMap) {
    let i = inMap.outro.match(/</g).length
    while (i--) {
      node = node.lastChild
    }
  }
  // prevent tmp to be node's parentNode
  tmp.textContent = ''
  return node
}

const trim = function (string) {
  return (string || '').replace(/^[\s\uFEFF]+|[\s\uFEFF]+$/g, '')
}
export function addClass(el, cls) {
  if (!el)
  { return }
  let curClass = el.className
  const classes = (cls || '').split(' ')

  for (let i = 0, j = classes.length; i < j; i++) {
    const clsName = classes[i]
    if (!clsName)
    { continue }

    if (el.classList) {
      el.classList.add(clsName)
    } else if (!hasClass(el, clsName)) {
      curClass += ` ${clsName}`
    }
  }
  if (!el.classList) {
    el.setAttribute('class', curClass)
  }
}
export function hasClass(el, cls) {
  if (!el || !cls)
  { return false }
  if (cls.includes(' ')) {
    throw new Error('className should not contain space.')
  }
  if (el.classList) {
    return el.classList.contains(cls)
  } else {
    return (` ${el.className} `).includes(` ${cls} `)
  }
}
export function removeClass(el, cls) {
  if (!el || !cls)
  { return }
  const classes = cls.split(' ')
  let curClass = ` ${el.className} `

  for (let i = 0, j = classes.length; i < j; i++) {
    const clsName = classes[i]
    if (!clsName)
    { continue }

    if (el.classList) {
      el.classList.remove(clsName)
    } else if (hasClass(el, clsName)) {
      curClass = curClass.replace(` ${clsName} `, ' ')
    }
  }
  if (!el.classList) {
    el.setAttribute('class', trim(curClass))
  }
}

export function getStyle(element, styleName) {
  if (!element || !styleName)
  { return '' }
  styleName = toCamelCase(styleName)
  if (styleName === 'float') {
    styleName = 'cssFloat'
  }
  try {
    const style = element.style[styleName]
    if (style)
    { return style }
    const computed = document.defaultView?.getComputedStyle(element, '')
    return computed ? computed[styleName] : ''
  } catch (e) {
    return element.style[styleName]
  }
}

export function setStyle(element, styleName, value) {
  if (!element || !styleName)
  { return }

  if (typeof styleName === 'object') {
    Object.keys(styleName).forEach((prop) => {
      setStyle(element, prop, styleName[prop])
    })
  } else {
    if (styleName.startsWith('--')) {
      element.style.setProperty(styleName, value)
    } else {
      styleName = toCamelCase(styleName)
      element.style[styleName] = value
    }
  }
}

export function getTextWidth(el) {
  if (el instanceof HTMLElement) {
    const range = document.createRange()
    range.setStart(el, 0)
    range.setEnd(el, el.childNodes.length)
    const rangeWidth = range.getBoundingClientRect().width
    const padding
    = (parseInt(getStyle(el, 'paddingLeft'), 10) || 0)
    + (parseInt(getStyle(el, 'paddingRight'), 10) || 0)
    return rangeWidth + padding
  }
  const { fontSize = '12', text } = el
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  context.font = `${fontSize}px Arial`
  const metrics = context.measureText(text)
  return metrics.width
}

export function createScript(attributes = {}, loaded = () => {}, tag = 'script') {
  const { resolve, reject, promise } = getPromise()
  const script = document.createElement(tag)
  for (const key in attributes) {
    script.setAttribute(key, attributes[key])
  }
  script.onload = () => {
    loaded()
    resolve()
  }
  script.onerror = () => reject()
  document.head.appendChild(script)
  return promise
}

export const getNearestScrollElement = (element, rootParent = window) => {
  let currentNode = element
  while (
    currentNode
    && currentNode.tagName !== 'HTML'
    && currentNode.tagName !== 'BODY'
    && currentNode.nodeType === 1
    && currentNode !== rootParent
  ) {
    const overflowY
      = document.defaultView?.getComputedStyle(currentNode).overflowY
    if (overflowY === 'scroll' || overflowY === 'auto') {
      return currentNode
    }
    currentNode = currentNode.parentNode
  }
  return rootParent
}

export const onImgLoaded = (img) => {
  const { promise, resolve } = getPromise()
  const img2Load = new Image()
  img2Load.src = img
  img2Load.onload = () => resolve(img2Load)
  img2Load.onerror = () => resolve(img2Load)
  return promise
}
