import { BiLogger, type BizLog } from './base'

export interface PerformanceBizLog extends BizLog {
  action: 'performance'
  dns: number
  tcp: number
  request: number
  response: number
  process: number
  domContentLoaded: number
  total: number
}
type LogType = Pick<BizLog, 'biz' | 'appName' | 'pageName'>
const PERFORMANCE_DEFAULT_BI_LOG_OPTIONS = Object.freeze({ percent: 0.6, max: 1 })
export abstract class PerformanceBiLogger extends BiLogger<PerformanceBizLog, LogType> {
  constructor(options?: ConstructorParameters<typeof BiLogger>[0]) {
    super({ ...PERFORMANCE_DEFAULT_BI_LOG_OPTIONS, ...(options || {}) })
  }

  processLog = (log: PerformanceBizLog) => log
  override process = (log: LogType) => {
    if (document.readyState !== 'complete') {
      window.addEventListener('load', () => this.log(log))
      return
    }
    const performanceLog = log as PerformanceBizLog
    performance.getEntriesByType('navigation').forEach((entry) => {
      const timing = entry as PerformanceNavigationTiming
      performanceLog.action = 'performance'
      performanceLog.dns = Math.ceil(timing.domainLookupEnd - timing.domainLookupStart)
      performanceLog.tcp = Math.ceil(timing.connectEnd - timing.connectStart)
      performanceLog.request = Math.ceil(timing.responseStart - timing.requestStart)
      performanceLog.response = Math.ceil(timing.responseEnd - timing.responseStart)
      performanceLog.process = Math.ceil(timing.domComplete - timing.domInteractive)
      performanceLog.domContentLoaded = Math.ceil(
        timing.domContentLoadedEventEnd - timing.domainLookupStart,
      )
      performanceLog.total = Math.ceil(timing.domComplete - timing.domainLookupStart)
      performanceLog.pageUrl = window.location.href
    })
    performanceLog.level = 'info'
    return this.processLog(performanceLog)
  }
}
