<template>
  <ElDialog
    v-model="visibility"
    top="0"
    :close-on-click-modal="false"
    :width="width"
    v-bind="$attrs"
    :class="`qs-dialog ${customClass}`"
    @close="close"
    @closed="closed"
    @open="open"
    @opened="opened"
  >
    <template v-if="hasHeader" #header>
      <div class="dialog-title flex-center-v">
        <i class="title-prefix-icon" />
        <slot name="title">
          <span>{{ title }}</span>
        </slot>
      </div>
    </template>
    <slot />
    <template v-if="hasFooter" #footer>
      <slot name="footer">
        <div class="footer-btn flex-center-v">
          <div
            v-if="prompt"
            class="prompt-text"
          >
            {{ prompt }}
          </div>
          <div
            v-if="cancelTxt"
            :class="`btn btn-master-light ml-auto btn-${type}-light cancel-btn`"
            @click="cancel"
          >
            {{ cancelTxt }}
          </div>
          <ElButton
            v-if="confirmTxt"
            :class="`btn btn-master ml16 btn-${type}-dark confirm-btn pr`"
            :loading="confirmLoading"
            :disabled="confirmDisabled"
            @click="confirm"
          >
            {{ confirmTxt }}
          </ElButton>
        </div>
      </slot>
    </template>
  </ElDialog>
</template>

<script>
import { ElButton, ElDialog } from 'element-plus'

export default {
  components: { ElButton, ElDialog },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '标题',
    },
    width: {
      type: String,
      default: '648px',
    },
    hasFooter: {
      type: Boolean,
      default: true,
    },
    hasHeader: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'green',
    },
    customClass: {
      type: String,
      default: '',
    },
    handleConfirm: {
      // 确定按钮回调，返回 promise 用于显示 loading 状态
      type: Function,
      default: () => Promise.resolve(),
    },
    confirmTxt: {
      type: String,
      default: '确定',
    },
    cancelTxt: {
      type: String,
      default: '取消',
    },
    confirmDisabled: {
      type: Boolean,
      default: false,
    },
    prompt: {
      type: String,
      default: '',
    },
  },
  emits: [
    'update:visible',
    'open',
    'opened',
    'close',
    'closed',
    'cancel',
    'check',
    'confirm',
  ],
  data() {
    return { confirmLoading: false }
  },
  computed: {
    visibility: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      },
    },
  },
  watch: {
    visible() {
      if (window.parent !== window) {
        window.parent.postMessage(
          { uiUpdate: { overlay: !!this.visible } },
          '*',
        )
      }
    },
  },
  methods: {
    open() {
      this.$emit('open')
    },
    opened() {
      this.$emit('opened')
    },
    close() {
      this.visibility = false
      this.$emit('close')
    },
    cancel() {
      this.close()
      this.$emit('cancel')
    },
    closed() {
      this.$emit('closed')
    },
    confirm() {
      if (!this.visibility) {
        return
      }
      this.confirmLoading = true
      this.handleConfirm()
        .then(() => {
          this.$emit('check')
          this.$emit('confirm')
        })
        .catch(() => {})
        .finally(() => (this.confirmLoading = false))
    },
  },
}
</script>

<style lang="scss" scoped>
.prompt-text {
  display: flex;
  font-size: 14px;
  color: #86909c;
  align-items: center;
  &::before {
    margin-right: 4px;
    width: 16px;
    height: 16px;
    background: no-repeat center/cover url('@shared/img/icon/info-gray-icon.png');
    content: '';
  }
}

.cancel-btn {
  padding: 0;
}
.footer-btn {
  justify-content: flex-end;
}
</style>

<style lang="scss">
@import '@shared/style/color';

.dialog-title {
  i {
    margin-right: 0.5em;
    width: 0.94em;
    height: 0.94em;
    background: url('@shared/img/title-icon.png') no-repeat center/100% 100%;
  }
  span {
    font-family: PingFangSC-Medium, 'PingFang SC', system-ui;
    font-size: 18px;
    font-weight: bold;
    color: $dark;
  }
}

.el-overlay-dialog {
  display: flex;
  align-items: center;
}
.el-dialog {
  display: flex;
  border-radius: 0.25em;
  max-height: calc(100% - 100px);
  box-shadow: 0 0.5em 1.5em 0 rgb(52 62 76 / 20%);
  flex-direction: column;

  .el-dialog__header {
    padding: 2em;
  }
  .el-dialog__body {
    overflow: auto;
    padding: 0 2em 2em;
    font-size: 16px;
    flex: 1;
  }
  .el-dialog__footer {
    border-top: 1px solid $split-line;
    padding: 1em 2em;
    .footer-btn .btn {
      padding: 0;
      min-width: 80px;
      height: 32px;
    }
  }
  .el-dialog__header .el-dialog__headerbtn {
    top: 1em;
    right: 1em;
    width: 1.5em;
    height: 1.5em;
    background: url('@shared/img/close-icon.png') no-repeat center/cover;
  }
  .el-dialog__close {
    visibility: hidden;
    font-size: 1.5em;
  }
}
</style>
