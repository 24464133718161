import { removeToken, setToken } from '@shared/util/cookie'
import { SECOND_IN_MS } from '@shared/util/date'
import { RequestHeader } from '@shared/constants/sessionKeys'
import { logError } from '@shared/util/bi/log'
import { useEventListener } from '@vueuse/core'
import type { EventMessage, EventMeta } from '@common/types/embed'
import { route } from '@common/router'
import { formatUrlEncode, replaceUrlParam } from '@shared/util/url'

const PAGE_MALL = 'mall'
export const PAGE_MALL_EVENT = Object.freeze({
  SetToken: 'SetToken',
  Login: 'Login',
  BindPhone: 'BindPhone',
  ForceResetPassword: 'ForceResetPassword',
  Download: 'Download',
  Preview: 'Preview',
  Phone: 'Phone',
  Unknown: 'Unknown',
  Success: 'Success',
  Loaded: 'Loaded',
  ApiRequestError: 'ApiRequestError',
  PageBack: 'PageBack',
  GotoStudy: 'GotoStudy',
  WxPurchase: 'WxPurchase',
  AliPurchase: 'AliPurchase',
  Jump: 'Jump',
  SetSession: 'SetSession',
  OrderSuccess: 'OrderSuccess',
})

export const APP_PAGE_NAME = Object.freeze({
  TuitionPurchase: 'TuitionPurchase',
  TuitionOrderResult: 'TuitionOrderResult',
  LoginPage: 'LoginPage',
  VideoPage: 'VideoPage',
  QuizPage: 'QuizPage',
})

const PAGE_MALL_EVENT_CALLBACK: Record<string, (meta: EventMeta) => void> = Object.freeze({
  [PAGE_MALL_EVENT.SetToken]: (meta: EventMeta) => {
    if (meta.data && typeof meta.data === 'string') {
      setToken(meta.data)
    }
  },
  [PAGE_MALL_EVENT.Success]: () => {
    if (location.href.includes('login')) {
      location.reload()
    }
  },
})

let login = false
export const EMBED_LOGIN_TIME = 'embed-login-time'
const inEmbed = location.search.includes('inApp') || window.webkit?.messageHandlers?.mallChannel || window.mallChannel
export const isEmbed = () => inEmbed
const isLinkRequireLogin = () => !location.href.includes('ignoreRefreshToken') && route.value.meta?.['requiresAuth']
export function injectEmbedListener() {
  window.PxSdk = {
    setToken: (token: string) => {
      try {
        postMessage(PAGE_MALL_EVENT.Success, {})
        removeToken()
        setToken(token)
        if (location.href.toLocaleLowerCase().includes('login')) {
          localStorage.setItem(EMBED_LOGIN_TIME, `${Date.now()}`)
        }
        login = true
        return true
      } catch (e) {
        return false
      }
    },
    setRequestHeader: (userAgent: string, deviceInfo: string) => {
      sessionStorage.setItem(RequestHeader.UserAgentQS, userAgent)
      sessionStorage.setItem(RequestHeader.DeviceInfoQS, deviceInfo)
    },
    setAppVersion: (version: string) => {
      sessionStorage.setItem('app-version', version)
    },
    reload: () => {
      location.reload()
    },
  }
  postMessage(PAGE_MALL_EVENT.SetSession, {})
  setTimeout(() => {
    postMessage(PAGE_MALL_EVENT.Loaded, {})
  }, 3000)
  if (!isEmbed()) {
    return
  }
  if (
    !login
    && !(Date.now() - Number(localStorage.getItem(EMBED_LOGIN_TIME)) < 10 * SECOND_IN_MS)
    && isLinkRequireLogin()
  ) {
    removeToken()
    localStorage.removeItem(EMBED_LOGIN_TIME)
  }
  useEventListener(window, 'message', (e) => {
    const { page, event = '', meta = {} } = e.data
    if (page !== PAGE_MALL) {
      return
    }
    const eventCallback = PAGE_MALL_EVENT_CALLBACK[event as string]
    eventCallback && eventCallback(meta)
  })
}

const wxMiniProgramRedirect = (event: string, meta: EventMeta) => {
  switch (event) {
    case PAGE_MALL_EVENT.Login:
      window.wx!.miniProgram!.navigateBack()
      break
    case PAGE_MALL_EVENT.Preview:
      window.wx!.miniProgram!.navigateTo({
        url: `/package-study/pages/file-preview/file-preview?name=${meta.name}&url=${encodeURIComponent(meta.data!)}&download=true&size=${meta.size || ''}&fromH5=true`,
      })
      break
    case PAGE_MALL_EVENT.Jump:
      window.wx!.miniProgram!.navigateTo({
        url: `/package-study/pages/schedule-play-detail/schedule-play-detail?classId=${meta.classId}&courseId=${meta.courseId}&schoolId=${meta.schoolId}&contentId=${meta.contentId}&initialTime=${meta.position}`,
      })
      break
  }
}
export function postMessage(event: string, meta: EventMeta, onlyLogin = false) {
  if (onlyLogin && !login) {
    return
  }
  if (window.wx?.miniProgram) {
    wxMiniProgramRedirect(event, meta)
    return
  }
  const message = { page: PAGE_MALL, event, meta }
  try {
    window.postMessage(message, '*')
    window.mallChannel?.postMessage(JSON.stringify(message))
    window.webkit?.messageHandlers?.mallChannel?.postMessage(JSON.stringify(message))
    // eslint-disable-next-line no-console
    console.info(`[mallChannel]: ${JSON.stringify(message)}`)
  } catch (e) {
    if (e instanceof Error) {
      logError(`[mallChannel]: ${JSON.stringify(message)}\n ${e.message}`)
    }
  }
}

export function doSupportEmbed(action: Function, message: EventMessage) {
  if (isEmbed()) {
    postMessage(message.event, message.meta || {})
  } else {
    action && action()
  }
}
export function encodeEmbedSourceUrl(url: string) {
  return replaceUrlParam(formatUrlEncode(url), 'qsEncoded', true)
}

export const isMiniProgram = /miniProgram/i.test(navigator.userAgent)
