import { defineStore } from 'pinia'
import { parseBoolean } from '@shared/util/boolean'
import type { Nullable } from '@shared/util/types'
import { MB_IN_BYTE } from '@shared/util/byte'
import { useMediaQuery } from '@vueuse/core'
import type { PaymentSetting } from '@common/api/px/mall/purchase'
import { PARTNER_SETTING } from '@common/constants/manager/share'
import { MENU_SCENES } from '@common/constants/mall/nav'
import { getOrganizationDetail } from '@common/api/api/organization'
import type { CustomDomainResponse, PermissionItem } from '@common/api/api/organization'
import { getModuleSetting } from '@common/api/px/mall/module'
import { getPaymentSetting } from '@common/api/px/mall/purchase'
import type { ClassFilterData, ISchoolInfo } from '@common/api/px/mall/index'
import type { MallModule } from '@common/api/px/mall/module'
import { getClassFilter, getSchoolInfo, getStudentPartnerSetting } from '@common/api/px/mall/index'

interface INetSchoolState {
  info: Nullable<ISchoolInfo> | null
  classFilter: Nullable<ClassFilterData> | null
  paymentSetting: Nullable<PaymentSetting> | null
  moduleSetting: Nullable<MallModule[]> | null
  customDomain: Nullable<CustomDomainResponse> | null
  orgPermissions: Nullable<PermissionItem[]> | null
  salesDisplay: boolean
  mallDisplay: boolean | null
  studentExpiredTimeDisplay: boolean | null
  newAccessControlSystem: boolean | null
  turnToLoginPage: boolean | null
  loginPageUrl: string | null
  allowComment: boolean
}
export const useNetSchoolStore = defineStore({
  id: 'netSchool',
  state: (): INetSchoolState => ({
    info: null,
    classFilter: null,
    paymentSetting: null,
    moduleSetting: null,
    customDomain: null,
    orgPermissions: null,
    mallDisplay: null,
    salesDisplay: false,
    studentExpiredTimeDisplay: false,
    newAccessControlSystem: false,
    turnToLoginPage: false,
    loginPageUrl: '',
    allowComment: false,
  }),

  getters: {
    pageLogo: state => state.info?.pageLogo,
    pageTitle: state => state.info?.pageTitle,

    logo: state => state.info?.logo,
    courseSiteDomain: state =>
      state.customDomain?.domainHost ? state.customDomain.domainHost : import.meta.env.VITE_SITE_URL,
    teacherSiteDomain: (state) => {
      if (!state.customDomain?.domainHost) {
        return import.meta.env.VITE_TEACHER_URL
      }
      // https://www.hzscjy.cn => https://teacher.hzscjy.cn
      return state.customDomain.tpDomainHost || state.customDomain.domainHost.replace(/(http(?:s)?:\/\/)?([^.]+)/, '$1teacher')
    },

    getPermissionValue: state => (key: string) => {
      const permission = (state.orgPermissions || []).find(p => p?.key === key)
      if (permission == null) {
        return permission
      }
      if (permission.value === 'true' || permission.value === 'false') {
        return parseBoolean(permission?.value)
      }
      return Number(permission?.value)
    },
    hasPermission: (state: any) => (key: string) => {
      const permission = state.getPermissionValue(key)
      // 没有对应key，按没有限制处理
      if (permission == null) {
        return true
      }
      if (typeof permission === 'boolean') {
        return permission
      }
      return permission > 0
    },
    // 流量限制
    isTrafficRemained: (state: any): boolean => state.hasPermission('Education.Resource.RemainingTraffic'),
    isStorageRemained: (state: any): boolean => state.hasPermission('Education.Resource.RemainingStorage'),
    hasMall: (state: any): boolean => state.hasPermission('Recruit.Mall'),
    // 对于采用旧版收费机制的机构，文件大小小于1000M，视频不可大于2000M
    fileUploadLimit: (state: any): number => (state.getPermissionValue('Education.Resource.UploadFileSizeLimit') || 1000) * MB_IN_BYTE,
    videoUploadLimit: (state: any): number => (state.getPermissionValue('Education.Resource.UploadVideoSizeLimit') || 2000) * MB_IN_BYTE,
    remindStorage: (state: any): number => state.getPermissionValue('Education.Resource.RemainingStorage') * MB_IN_BYTE,
    // 直播限制
    allowShareScreen: (state: any): boolean => state.hasPermission('Education.Live.ShareScreen'),
    allowHandUp: (state: any): boolean => state.hasPermission('Education.Live.Chat'),
    allowInteraction: (state: any): boolean => state.hasPermission('Education.Live.Interaction'),
    allowPaintbrush: (state: any): boolean => state.hasPermission('Education.Live.Paintbrush'),

    moduleSettings(): MallModule[] {
      return (this.moduleSetting || [])
        .filter(item => item?.isShow || item?.show)
        .map(item => (item!.children || []).filter(v => v.isShow || v.show).concat(item as MallModule))
        .reduce((res, items) => res.concat(items), [])
    },
    isModuleShow() {
      return (moduleId: number[] | number): boolean => {
        if (Array.isArray(moduleId)) {
          return moduleId.every(id => this.isModuleShow(id))
        }
        return !!this.moduleSettings.find(v => v.moduleId === moduleId)
      }
    },
  },

  actions: {
    getInfo() {
      return getSchoolInfo({
        withResourceInfo: true,
      }).then((result) => {
        this.info = result.data || null
        return result
      })
    },
    getPaymentSetting(force = false) {
      return getPaymentSetting(force).then((result) => {
        this.paymentSetting = result.data || null
        return result
      })
    },
    getModuleSetting() {
      const scene = useMediaQuery('(min-width: 1184px)').value
        ? MENU_SCENES.enums.Web.value
        : MENU_SCENES.enums.Mobile.value
      return getModuleSetting({ scene, needCache: true }).then((res) => {
        this.moduleSetting = res.data || null
        return res
      })
    },

    getAllClassFilter() {
      return getClassFilter().then((result) => {
        this.classFilter = result.data || null
        return result
      })
    },

    getStudentPartner() {
      return getStudentPartnerSetting({
        settingType: PARTNER_SETTING.MALL_DISPLAY,
      }).then((res) => {
        this.mallDisplay = !!res.data
        return !!res.data
      })
    },

    getOrgDetail() {
      return getOrganizationDetail().then((res) => {
        this.customDomain = res?.data?.customDomain || null
        this.orgPermissions = res?.data?.permissions || null
        this.salesDisplay = !!res?.data?.isSalesDisplay
        this.studentExpiredTimeDisplay = !!res?.data?.isStudentExpiredTimeDisplay
        this.newAccessControlSystem = !!res?.data?.isNewAccessControlSystem
        this.allowComment = !!res?.data?.allowComment
        this.turnToLoginPage = !!res?.data?.turnToLoginPage
        this.loginPageUrl = res?.data?.loginPageUrl || null
        return res
      })
    },

    clear() {
      if (this.mallDisplay) {
        this.mallDisplay = null
      }
    },
  },
})
