import { Toast } from 'vant'
import { useMediaQuery } from '@vueuse/core'
import { $error as $errorPc, $info as $infoPc, $success as $successPc } from './toaster'

const genToast = (pcFunction: Function) => {
  return (...args: any) => {
    useMediaQuery('(min-width: 1184px)').value ? pcFunction(...args) : Toast(...args)
  }
}
const $error = genToast($errorPc)
const $info = genToast($infoPc)
const $success = genToast($successPc)

export {
  $info,
  $error,
  $success,
}
