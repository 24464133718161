import type { RouteLocationNormalized } from 'vue-router'
import { useNetSchoolStore } from '@common/store/netSchool'
import { getPartner, route, router } from '.'

export const getLoginUrl = (to?: RouteLocationNormalized) => {
  if (useNetSchoolStore().turnToLoginPage) {
    return useNetSchoolStore().loginPageUrl
  }
  if (location.pathname.toLowerCase().includes('login')) {
    return location.href
  }
  let returnUrl = encodeURI(location.href)
  if (to) {
    try {
      returnUrl = encodeURI(router.resolve(to).href)
    } catch (e) {
    }
  }
  try {
    const target = router.resolve({
      name: 'Login',
      params: { ...route.value.params, ...getPartner() },
      query: { ref: returnUrl },
    })
    return target.href
  } catch (e) {
    return `${import.meta.env.VITE_HOME_URL}/Login?ref=${encodeURIComponent(location.href)}`
  }
}

export const goHome = () => {
  try {
    router.push({
      name: 'Home',
      params: { ...route.value.params, ...getPartner() },
    })
  } catch (e) {
    location.href = import.meta.env.VITE_HOME_URL
  }
}
export const gotoLogin = () => {
  if (location.href === getLoginUrl()) {
    return
  }
  location.href = getLoginUrl() || location.href
}
