import { USER_ID_KEY } from '../request'
import type { Nullable } from '../types'
import { getClientInfo } from './client'

export interface BaseLog {
  level: 'error' | 'info' | 'warn'
}
export interface BizLog extends BaseLog {
  biz: string
  appName?: string | undefined
  pageName?: string
  pageUrl?: string
  action?: string | undefined
  [key: string]: unknown
}

interface BiLogOptions {
  percent?: number
  max?: number
}
const DEFAULT_BI_LOG_OPTIONS: Readonly<Required<BiLogOptions>> = Object.freeze({
  percent: 1,
  max: 100,
})
export abstract class BiLogger<T extends BizLog, V> {
  private count: number
  private options: Required<BiLogOptions>
  public constructor(options: Nullable<BiLogOptions> | undefined | null = {}) {
    this.options = {
      ...(options || {}),
      percent: options?.percent || DEFAULT_BI_LOG_OPTIONS.percent,
      max: options?.max || DEFAULT_BI_LOG_OPTIONS.max,
    }
    this.count = 0
  }

  abstract send(logs: (T & ReturnType<typeof getClientInfo>)[]): Promise<unknown>
  abstract process(log: V): T | undefined
  private doLog = (log: T | undefined) => {
    if (!log) {
      return Promise.resolve()
    }
    if (import.meta.env.DEV || this.count >= this.options.max || Math.random() > this.options.percent) {
      return Promise.resolve()
    }
    this.count++
    return this.send([{
      userId: sessionStorage.getItem(USER_ID_KEY) || undefined,
      ...getClientInfo(),
      ...log,
    }])
  }

  log = (log: V) => this.doLog(this.process(log))
}
