import type { ApiUserInfo } from '@shared/constants/user'
import type { ApiCacheOptions } from '@shared/util/request'
import { ApiOptions, getRequestWithCache } from '..'

export const getUserInfo = (cacheOptions: ApiCacheOptions = {}) => {
  return getRequestWithCache<ApiUserInfo>(
    new ApiOptions({
      url: '/net/api/account/info',
    }),
    cacheOptions,
  )
}
