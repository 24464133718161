const STAGE = 1024
export const KB_IN_BYTE = STAGE
export const MB_IN_BYTE = STAGE * KB_IN_BYTE
export const GB_IN_BYTE = STAGE * MB_IN_BYTE
export const TB_IN_BYTE = STAGE * GB_IN_BYTE

export const ONE_DECIMAL = 10
export const TWO_DECIMAL = 100
export const THREE_DECIMAL = 1000

const enum UNIT_NAME {
  TB = 'TB',
  GB = 'GB',
  MB = 'MB',
  KB = 'KB',
}

export const transferByte2MB = (byte: number) => {
  return sizeFormat(byte, MB_IN_BYTE, UNIT_NAME.MB)
}

export function getSize(byte: number) {
  if (byte >= TB_IN_BYTE) {
    return sizeFormat(byte, TB_IN_BYTE, UNIT_NAME.TB)
  } else if (byte >= GB_IN_BYTE) {
    return sizeFormat(byte, GB_IN_BYTE, UNIT_NAME.GB)
  } else if (byte >= MB_IN_BYTE) {
    return sizeFormat(byte, MB_IN_BYTE, UNIT_NAME.MB)
  } else if (byte >= KB_IN_BYTE) {
    return sizeFormat(byte, KB_IN_BYTE, UNIT_NAME.KB)
  } else {
    return `${Number(byte)}B`
  }
}

function sizeFormat(size: number, unit: number, unitName: UNIT_NAME) {
  return `${Math.floor((Number(size) * 10) / unit) / 10}${unitName}`
}

function sizeRoundFormat(size: number, decimalPlaces: number, unit: number, unitName: UNIT_NAME) {
  return `${Math.round((Number(size) * decimalPlaces) / unit) / decimalPlaces}${unitName}`
}

export function getRoundSize(byte: number, decimalPlaces: number = ONE_DECIMAL) {
  if (byte >= TB_IN_BYTE) {
    return sizeRoundFormat(byte, decimalPlaces, TB_IN_BYTE, UNIT_NAME.TB)
  } else if (byte >= GB_IN_BYTE) {
    return sizeRoundFormat(byte, decimalPlaces, GB_IN_BYTE, UNIT_NAME.GB)
  } else if (byte >= MB_IN_BYTE) {
    return sizeRoundFormat(byte, decimalPlaces, MB_IN_BYTE, UNIT_NAME.MB)
  } else {
    return sizeRoundFormat(byte, decimalPlaces, KB_IN_BYTE, UNIT_NAME.KB)
  }
}

export const transferByte2MBWithRound = (byte: number, decimalPlaces: number = ONE_DECIMAL) => {
  return sizeRoundFormat(byte, decimalPlaces, MB_IN_BYTE, UNIT_NAME.MB)
}
