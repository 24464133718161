import { $error, $info, $success, genToaster } from '@qs-plus/components/toaster'

const $pxSuccess = genToaster('success', 'qs-toaster px-success-toaster')

export { $info, $error, $success, $pxSuccess }

export default {
  install: (app) => {
    app.config.globalProperties.$success = $success
    app.config.globalProperties.$pxSuccess = $pxSuccess
    app.config.globalProperties.$error = $error
    app.config.globalProperties.$info = $info
  },
}
