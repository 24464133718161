import { TOKEN_DEPRECATED } from '@shared/constants/hr'
import type {
  BaseApiOptionParams,
  QsAxiosRequestConfig,
  QsAxiosResponseHeaders,
} from '@shared/util/request'
import { ANONYMOUS_TOKEN_KEY, BaseApiOptions, genRequest } from '@shared/util/request'
import type { AxiosResponse } from 'axios'
import { getToken } from '@shared/util/cookie'
import { useUserStore } from '@common/store/user'
import { goHome, gotoLogin } from '@common/router/util.redirect'
import { getPartner, isManager, route, router } from '@common/router/index'
import { $error } from '@common/plugin/toaster.common'
import { PAGE_MALL_EVENT, doSupportEmbed } from '@common/util/embed.client'
import { refreshToken } from './px/app'

const MOCK_ENABLE
  = import.meta.env.DEV && import.meta.env.VITE_MOCK_ENABLE === 'true'

const MOCK_ALL = MOCK_ENABLE && import.meta.env.VITE_MOCK_ALL === 'true'

interface PxApiOptionParams extends BaseApiOptionParams {
  apiVersion?: string
}
export class ApiOptions extends BaseApiOptions {
  constructor(option: PxApiOptionParams) {
    super(option)
    this.url = this.url.replace(
      /^\/net\/api\//,
      `/net/api/${option.apiVersion || import.meta.env.VITE_API_VERSION}/`,
    )
    this.mock = MOCK_ALL || (MOCK_ENABLE && !!option.mock)
  }

  override get baseUrl() {
    return `${this.mock ? '/mock' : '/mall'}`
  }
}

const REFERER_REDIRECT_MAP: Record<string, string> = {
  '/BindPhone': 'BindPhone',
  '/ForceResetPassword': 'ForceResetPassword',
}
const resolve403 = (referer?: string) => {
  if (referer) {
    if (referer.toLowerCase().includes('login')) {
      doSupportEmbed(() => {
        useUserStore().logout()
      }, { event: PAGE_MALL_EVENT.Unknown })
    }
    if (REFERER_REDIRECT_MAP[referer]
        && router.resolve({
          name: REFERER_REDIRECT_MAP[referer] as string,
          params: { ...route.value.params, ...getPartner() },
        }).href != null) {
      doSupportEmbed(() => {
        route.value.name !== REFERER_REDIRECT_MAP[referer as string] && router.push({
          name: REFERER_REDIRECT_MAP[referer as string] as string,
          params: { ...route.value.params, ...getPartner() },
          query: {
            ref: location.origin + router.resolve(route.value).href,
            ...route.value.query,
          },
        })
      }, { event: REFERER_REDIRECT_MAP[referer] as string })
      return
    }
    if (isManager()) {
      doSupportEmbed(() => {
        referer += `${(referer as string).includes('?') ? '&' : '?'}ignoreDownload=true&noClose=true`
        location.href = referer as string
      }, { event: PAGE_MALL_EVENT.Unknown })
    }
  } else if (!(route.value.name as string).includes('Home')) {
    router.push({ name: 'notFoundSpecify' })
  }
}
const errorHandle = (
  errorResponse: AxiosResponse,
): void | Promise<any> => {
  switch (errorResponse.status) {
    case 401:
      if (
        String(TOKEN_DEPRECATED) === errorResponse.headers['authorization-qs-code']
        && !(errorResponse.config as QsAxiosRequestConfig)._retry
      ) {
        return refreshToken().then(() => {
          (errorResponse.config as QsAxiosRequestConfig)._retry = true
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          return request(errorResponse.config, new ApiOptions({ url: '' }))
        }).catch(() => {
          throw new Error('账户信息已失效，请重新登录!')
        })
      }
      !getToken() && localStorage.removeItem(ANONYMOUS_TOKEN_KEY)
      useUserStore().logout(false)
      doSupportEmbed(() => {
        $error('账户信息已失效，请重新登录')
        gotoLogin()
      }, { event: PAGE_MALL_EVENT.Login })
      break

    case 403:
      !(errorResponse.config as QsAxiosRequestConfig).qsArg?.ignore403
        && resolve403((errorResponse.headers as QsAxiosResponseHeaders).referer)
      break

    case 302:
      $error('暂时没有权限操作哦, 正在为您重新导航')
      setTimeout(goHome, 500)
      break
  }
}

const { getRequest, postRequest, request, getRequestWithCache, requestWithCache, clearRequestCache }
= genRequest(ApiOptions, errorHandle)
export { getRequest, postRequest, getRequestWithCache, requestWithCache, clearRequestCache }
