export const routerModuleErrorHandler = (router) => {
  router.onError((error) => {
    const pattern
      = /Loading chunk .+ failed|Failed to fetch dynamically imported module|Not Found/ig
    if (error.message.match(pattern)) {
      location.reload()
    }
  })
}

const ErrorReloadKey = `error-reload-${new Date().toLocaleDateString()}`
const hasReload = localStorage.getItem(ErrorReloadKey)
let errorCount = 0
document.addEventListener(
  'error',
  (e) => {
    if (e?.target?.nodeName?.toUpperCase() === 'SCRIPT') {
      errorCount++
    }
    if (!hasReload && errorCount > 10) {
      localStorage.setItem(ErrorReloadKey, true)
      window.location.reload()
    }
  },
  true,
)
