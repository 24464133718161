import type { ApiUserInfo, UserSchool } from '@shared/constants/user'
import { USER_ROLE } from '@shared/constants/user'
import { removeToken } from '@shared/util/cookie'
import type { ApiCacheOptions } from '@shared/util/request'
import { defineStore } from 'pinia'
import { clearRequestCache } from '@common/api/index'
import { PAGE_MALL_EVENT, postMessage } from '@common/util/embed.client'
import type { UserModuleInfo } from '@common/api/px/user'
import { getUserModuleInfo } from '@common/api/px/user'
import { getModule, isModuleActionAllowed } from '@common/util/module'
import { getUserInfo } from '@common/api/api/account'
import { useDeviceStore } from './device'
import { useNetSchoolStore } from './netSchool'

interface IUserState {
  userInfo: ApiUserInfo | null
  studentModuleInfo: UserModuleInfo | null
  teacherModuleInfo: UserModuleInfo | null
}
export const useUserStore = defineStore({
  id: 'user',
  state: (): IUserState => ({
    userInfo: null,
    studentModuleInfo: null,
    teacherModuleInfo: null,
  }),
  getters: {
    userOrganizationMap: (state) => {
      const result: Record<string, UserSchool[]> = {}
      const userSchools = state.userInfo?.userSchools || []
      for (const userSchool of userSchools) {
        if (userSchool.organizationId != null) {
          result[userSchool.organizationId] = result[userSchool.organizationId] || []
          result[userSchool.organizationId]!.push(userSchool)
        }
      }
      return result
    },
    getStudentOrganization() {
      return (partnerId: string | number) => {
        const userSchoolMap = this.userOrganizationMap[partnerId]
        return userSchoolMap?.filter(
          userSchool => userSchool.userRole === +USER_ROLE['Student']!,
        ) || []
      }
    },
    getTeacherOrganization() {
      return (partnerId: string | number) => {
        const userSchoolMap = this.userOrganizationMap[partnerId]
        return userSchoolMap?.filter(
          userSchool => userSchool.userRole !== +USER_ROLE['Student']!,
        ) || []
      }
    },
    getDistributeTeacherOrganizationByParent() {
      return (parentPartnerId: string | number) => {
        const orgs: UserSchool[] = []
        const userSchools = this.userInfo?.userSchools || []
        for (const userSchool of userSchools) {
          if (userSchool.parentOrganizationId === parentPartnerId
            && !orgs.some(org => org.organizationId === userSchool.organizationId)
          ) {
            orgs.push(userSchool)
          }
        }
        return orgs?.filter(userSchool => userSchool.userRole !== +USER_ROLE['Student']!) || []
      }
    },
    teacherRole: state => state.teacherModuleInfo?.role,
    getStudentSchool() {
      return (partnerId: string | number, category: number) => {
        const userSchools = this.getStudentOrganization(partnerId)
        return userSchools.find(userSchool => userSchool.category === category)
      }
    },
    getTeacherSchool() {
      return (partnerId: string | number, category?: number) => {
        const userSchools = this.getTeacherOrganization(partnerId)
        return userSchools.find(userSchool => category == null || userSchool.category === category)
      }
    },
    isStudentBelongCategory() {
      return (partnerId: string | number, category: number) => {
        const studentSchool = this.getStudentSchool(partnerId, category)
        return !!studentSchool
      }
    },
    hasTeacherModule() {
      return (moduleId: number) => {
        const modules = this.teacherModuleInfo?.userModuleTreeNodeUIDataList || []
        return !!getModule(modules, moduleId)
      }
    },
    hasTeacherModuleAllowed() {
      return (data: { moduleId: number; action?: string }) => {
        return isModuleActionAllowed(
          this.teacherModuleInfo?.userModuleTreeNodeUIDataList || [],
          data.moduleId,
          data.action,
        )
      }
    },
  },
  actions: {
    getUserInfo(force = false) {
      return getUserInfo({ force }).then((result) => {
        this.userInfo = result.data || null
        return result
      })
    },
    getUserModuleInfo(role: 'Teacher' | 'Student', cacheOptions?: ApiCacheOptions) {
      return getUserModuleInfo(role, cacheOptions).then((res) => {
        if (role === 'Teacher') {
          this.teacherModuleInfo = res.data || null
        } else {
          this.studentModuleInfo = res.data || null
        }
        return res
      })
    },

    createAnonymous() {
      useDeviceStore()
      postMessage(PAGE_MALL_EVENT.Login, {}, true)
      return Promise.resolve()
    },
    logout(cleanAll = true) {
      removeToken()
      cleanAll ? this.clear() : this.shadowClear()
      return this.createAnonymous()
    },
    clear() {
      localStorage.clear()
      sessionStorage.clear()
      this.shadowClear()
    },
    shadowClear() {
      this.userInfo = null
      this.teacherModuleInfo = null
      this.studentModuleInfo = null
      clearRequestCache()
      useNetSchoolStore().clear()
    },
  },
})
