/* eslint-disable import/no-mutable-exports */
import type { Ref } from 'vue'
import { getCurrentInstance } from 'vue'
import type { RouteLocationNormalized, Router } from 'vue-router'

let _router: Router
let _route: Ref<RouteLocationNormalized>
let _isManager: boolean

export { _route as route, _router as router }

export const registerRouter = (route: Ref<RouteLocationNormalized>, router: Router, isManager = false) => {
  _route = route
  _router = router
  _isManager = isManager
}

export const isManager = () => _isManager
export const getKey = (partnerType: string, partnerId: string | number) => btoa(`${partnerType}_${partnerId}`)
export const getUrlKey = (route: RouteLocationNormalized) =>
  getKey(
    (route.params['partnerType'] || route.meta['partnerType']) as string,
    (route.params['partnerId'] || route.meta['partnerId']) as string,
  )

let $partner: { id: string; type: string } | undefined
let partner: { partnerType: string; partnerId: string; key: string }
export const getPartner = (to?: RouteLocationNormalized) => {
  if (partner?.partnerId) {
    return partner
  }
  $partner = $partner
    ?? getCurrentInstance()?.appContext?.config?.globalProperties?.$partner
    ?? window.PX_APP_CONFIG?.$partner
  if ($partner) {
    const partnerType = $partner.type
    const partnerId = $partner.id
    return {
      partnerType,
      partnerId,
      key: getKey(partnerType, partnerId),
    }
  }
  const route = to || _route.value
  const partnerType = (route.params['partnerType'] || route.meta['partnerType']) as string
  const partnerId = (route.params['partnerId'] || route.meta['partnerId']) as string
  partner = { partnerType, partnerId, key: getUrlKey(route) }
  return partner
}

export const getSchool = (to?: RouteLocationNormalized) => {
  const route = to || _route.value
  const schoolId = (route.params['schoolId'] || route.meta['schoolId']) as string
  return { schoolId }
}
