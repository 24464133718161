import { Dialog } from 'vant'
import { isNormalBrowser } from '@shared/util/browser'
import QsDialog from '@qs-plus/components/QsDialog.vue'
import { isEmbed } from '@common/util/embed.client'
(function () {
  if (isEmbed() || isNormalBrowser()) {
    return
  }
  const openDialog = () => {
    if (location.href.includes('/mobile/')) {
      Dialog.alert({
        title: '提示',
        message: '糟糕，不支持当前浏览器，推荐使用谷歌浏览器，会提高您的使用体验。',
        confirmButtonText: '我知道了',
        confirmButtonColor: '#175CE4',
        overlayStyle: {
          zIndex: 10001,
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: 'rgba(0, 0, 0, .7)',
        },
        zIndex: 10002,
      })
    } else {
      const checkDialog = createApp({
        render: () => h(QsDialog, {
          visible: true,
          cancelTxt: '',
          title: '提示',
          confirmTxt: '我知道了',
          onConfirm() {
            checkDialog.unmount()
          },
        }, [h('span', '糟糕，不支持当前浏览器，推荐使用谷歌浏览器，会提高您的使用体验。')]),
      })
      checkDialog.mount('#checkBrowserDialog')
    }
  }

  setTimeout(openDialog, 1000)
})()
