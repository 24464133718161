import { Constant } from '@shared/constants/index'
import { now } from '@shared/util/date'
import { useNetSchoolStore } from '@common/store/netSchool'

export const MALL_MODULE_MENU = Object.freeze({
  Home: 10,
  HomeTeacherGroup: 11,
  HomeExamGuide: 12,
  HomeMaterial: 13,
  HomeQuestion: 14,
  HomeCouponCenter: 15,
  HomeAboutUs: 16,

  CourseCenter: 30,
  TeacherGroup: 50,
  ExamGuide: 70,
  Material: 90,
  Question: 110,
  CouponCenter: 130,

  AboutUs: 150,
  AboutUsAboutUs: 151,
  AboutUsCompanyProfiles: 152,
  AboutUsCommonProblems: 153,

  AppDownload: 170,

  Other: 190,
  OtherCouponDialog: 191,
  OtherAppQrcode: 192,
  OtherWxQrcode: 193,

  MobileHome: 500,
  MobileHomeExamGuide: 501,
  MobileHomeMaterial: 502,
  MobileHomeTeacherGroup: 503,
  MobileHomeQuestion: 504,
  MobileHomeCouponCenter: 505,
  MobileHomeAboutUs: 506,
})

export const NAV_TAB_LIST = Object.freeze(
  new Constant({
    Home: {
      label: '首页',
      path: 'Home',
      value: MALL_MODULE_MENU.Home,
    },
    CourseCenter: {
      label: '选课中心',
      path: 'CourseCenter',
      value: MALL_MODULE_MENU.CourseCenter,
    },
    TeacherTeam: {
      label: '师资团队',
      path: 'Teach',
      value: MALL_MODULE_MENU.TeacherGroup,
    },
    Guide: {
      label: '报考指南',
      path: 'Guide',
      value: MALL_MODULE_MENU.ExamGuide,
    },
    Material: {
      label: '考试资料',
      path: 'Material',
      value: MALL_MODULE_MENU.Material,
    },
    Coupon: {
      label: '领券中心',
      path: 'CouponCenter',
      value: MALL_MODULE_MENU.CouponCenter,
    },
    Question: {
      label: '题库下载',
      path: 'QuestionBank',
      value: MALL_MODULE_MENU.Question,
    },
    AboutUs: {
      label: '关于我们',
      path: 'About',
      value: MALL_MODULE_MENU.AboutUs,
    },
    AppDownload: {
      label: 'APP下载',
      path: 'AppDownload',
      value: MALL_MODULE_MENU.AppDownload,
    },
  }),
)

export const FOOTER_RECORD = Object.freeze(
  new Constant({
    Copyright: {
      path: '',
      getName: () => `© 2022-${new Date(now()).getFullYear()} ${useNetSchoolStore().customDomain?.copyright || '苏州青颖飞帆软件科技股份有限公司'}版权所有`,
      label: '版权所有',
      value: 1,
    },
    ICP: {
      getName: () => useNetSchoolStore().customDomain?.icpCode || '苏ICP备11082541号',
      path: 'http://beian.miit.gov.cn',
      label: '苏ICP备11082541号',
      value: 2,
    },
    Public: {
      getName: () => useNetSchoolStore().customDomain?.publicSecurityCode || '苏公网安备 32011402010226号',
      path: 'http://www.beian.gov.cn/portal/registerSystemInfo',
      label: '苏公网安备 32011402010226号',
      value: 3,
    },
  }),
)

export const MENU_SCENES = Object.freeze(
  new Constant({
    Web: { label: '网页端', value: 1 },
    Mobile: { label: '移动端', value: 2 },
  }),
)
