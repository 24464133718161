import { detect } from 'detect-browser'

const BrowserPattern = Object.freeze({
  QsxtWebview: /QsxtWebview\/([\d.]+)/i,
  // TODO: remove AndroidWebView/IOSWebView after all clients are updated
  AndroidWebView: { exec: () => window.mallChannel ? ['AndroidWebView'] : undefined },
  IOSWebView: { exec: () => window.webkit?.messageHandlers.mallChannel ? ['IOSWebView'] : undefined },
  WechatWebView: /micromessenger\/([\d.]+).*webview/i,
  Wechat: /micromessenger\/([\d.]+)/i,
  Uc: /UCBrowser\/([\d.]+)/i,
  Quark: /quark\/([\d.]+)/i,
  Baidu: /(baiduboxapp)\/([\d.]+)/i,
  Sogou: /(SogouMSE)\/([\d.]+)/i,
  Qzone: /qzone\/.*_qz_([\d.]+)/i,
  QQBrowser: /(qqbrowser)\/([\d.]+)/i,
  QQ: /qq\/([\d.]+)/i,
  BytedanceWebview: /BytedanceWebview\/([\d.]+)/i,
  AlipayClient: /AlipayClient\/([\d.]+)/i,
  Feishu: /ChannelName\/Feishu/i,
  Weibo: /(weibo).*weibo__([\d.]+)/i,
})
function getBrowserType() {
  const ua = navigator.userAgent
  for (const browser in BrowserPattern) {
    const match = BrowserPattern[browser as keyof typeof BrowserPattern].exec(ua)
    if (match) {
      return { name: browser, version: match[1] || 'default' }
    }
  }
  return undefined
}
export function getClientInfo() {
  const browserInfo = detect()
  const browser = getBrowserType() || browserInfo
  const { width: screenWidth, height: screenHeight } = window.screen
  const { clientWidth, clientHeight } = document.documentElement
  return {
    agent: window.navigator.userAgent,
    os: browserInfo?.os,
    browserKernel: browserInfo?.name,
    browserKernelVersion: browserInfo?.version,
    browser: browser?.name,
    browserVersion: browser?.version,
    clientHeight,
    clientWidth,
    screenWidth,
    screenHeight,
  }
}
