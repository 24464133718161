import type { BizLog, PageViewBizLog } from '@shared/util/bi/index'
import { ErrorBiLogger, PageViewBiLogger, PerformanceBiLogger } from '@shared/util/bi/index'
import { parseBoolean } from '@shared/util/boolean'
import type { ErrorBizLog } from '@shared/util/bi/error'
import type { PerformanceBizLog } from '@shared/util/bi/performance'
import { ApiOptions, postRequest } from '..'
import { getPartner, route } from '../../router'

const PxBiConfig = Object.freeze({
  Biz: import.meta.env.VITE_SITE_NAME,
  Apps: {
    Default: 'Default',
    Manager: 'Manager',
    Mall: 'Mall',
    MallMobile: 'MallMobile',
  },
  Enable: {
    PageView: parseBoolean(import.meta.env.VITE_LOG_ENABLE_PAGE_VIEW),
    Error: parseBoolean(import.meta.env.VITE_LOG_ENABLE_ERROR),
    Performance: parseBoolean(import.meta.env.VITE_LOG_ENABLE_PERFORMANCE),
  },
})
// nginx handle
const collectLog = (logs: BizLog[], group = 'default') => {
  return postRequest(
    new ApiOptions({
      url: `/net/course/frontEnd/collect/${group}`,
      args: logs,
      ignore403: true,
    }),
  ).catch(() => {})
}

class PxPageViewBiLogger extends PageViewBiLogger {
  send = (logs: BizLog[]) => (PxBiConfig.Enable.PageView && collectLog(logs)) || Promise.resolve()
  override processLog = (log: PageViewBizLog) => {
    log.biz = PxBiConfig.Biz
    log['organizationId'] = getPartner().partnerId
    return log
  }
}
class PxErrorBiLogger extends ErrorBiLogger {
  send = (logs: BizLog[]) => (PxBiConfig.Enable.Error && collectLog(logs, 'error')) || Promise.resolve()
  override processLog = (log: ErrorBizLog) => {
    log.biz = PxBiConfig.Biz
    log.pageName = route.value.name as string
    return log
  }
}
class PxPerformanceBiLogger extends PerformanceBiLogger {
  send = (logs: BizLog[]) => (PxBiConfig.Enable.Performance && collectLog(logs, 'performance')) || Promise.resolve()
  override processLog = (log: PerformanceBizLog) => {
    log.biz = PxBiConfig.Biz
    log.pageName = route.value.name as string
    return log
  }
}

const isProduction = import.meta.env.VITE_BUSINESS_ENV === 'prod'
const pxPageViewBiLogger = new PxPageViewBiLogger()
const pxErrorBiLogger = new PxErrorBiLogger({
  percent: isProduction ? 0.3 : 1,
  max: isProduction ? 15 : 1000,
})

export {
  PxBiConfig,
  pxPageViewBiLogger,
  pxErrorBiLogger,
  PxPerformanceBiLogger,
}
