import { getPartner } from '@common/router'
import { ApiOptions, getRequestWithCache } from '../../index'

interface ModuleSettingRequest {
  organizationId?: number
  scene: number
  needCache?: boolean
}
export interface MallModule {
  moduleId: number
  name: string
  isShow: boolean
  enableCustomize: boolean
  children: MallModule[] | null
  show?: boolean
}
export const getModuleSetting = (params: ModuleSettingRequest) => {
  const { partnerId } = getPartner()
  return getRequestWithCache<MallModule[]>(
    new ApiOptions({
      url: '/net/course/Svc/Mall/Module/Setting',
      args: { ...params, organizationId: partnerId },
    }),
    { force: !params.needCache },
  )
}
