import { getPromise } from './promise'

export function delay(time) {
  const { promise, resolve } = getPromise()
  setTimeout(() => resolve(), time)
  return promise
}

export const throttle = (fn, maxWait) => {
  maxWait = Math.max(maxWait, 0)
  let timer
  let lastExec = 0
  const clear = () => {
    if (timer) {
      clearTimeout(timer)
      timer = undefined
    }
  }
  const invoke = (_this, _args) => {
    lastExec = Date.now()
    return fn.apply(_this, _args)
  }
  return function (...args) {
    clear()
    const elapsed = Date.now() - lastExec
    if (elapsed > maxWait) {
      return invoke(this, args)
    }
    timer = setTimeout(() => invoke(this, args), elapsed)
  }
}
