import type { PaginationList, PaginationRequest } from '@shared/util/request'
import { getPartner } from '@common/router/index'
import type { FilterItem } from '@common/constants/filter'
import type { MarketInfoType } from '@common/types/marketInfo'
import { ApiOptions, getRequest, getRequestWithCache, postRequest } from '../../index'

interface ContactData {
  title: string
  qrcode: string
  contents: string[]
  customQRCodes: customQRCode[]
}
interface customQRCode {
  title: string
  qrcode: string
}
interface AboutUsData {
  title: string
  content: string
}
interface PartnerSite {
  name: string
  link: string
}
export interface ISchoolInfo {
  logo: string
  pageTitle: string
  pageLogo: string
  servicePhone: string
  teacherSchoolId: number
  partnerName: string
  valid: boolean
  contactData: ContactData
  aboutUsData: AboutUsData
  companyProfiles: string[]
  commonProblem: string
  partnerSites: PartnerSite[]
  im: string
  hasCourse: boolean
  hasTeacher: boolean
  hasGuide: boolean
  hasContents: boolean
  hasQuestionBank: boolean
  distributor: boolean
}
export const getSchoolInfo = (args?: { withResourceInfo: boolean }, cacheOption = {}) => {
  const { partnerId } = getPartner()
  return getRequestWithCache<ISchoolInfo>(
    new ApiOptions({
      url: '/net/course/Svc/Mall/Info',
      args: {
        organizationId: partnerId,
        ...args,
      },
    }),
    cacheOption,
  )
}

interface ClassFilterTag {
  name: string
  targetKey: string
}
export interface ClassFilterItem extends FilterItem {
  courseType: number
  coverImage: string
  examTime: number
  tags: ClassFilterTag[]
  recommend: boolean
  hasValidTeacher: boolean
  hasValidCourse: boolean
  hasValidContent: boolean
  hasValidQuestionBank: boolean
  subNodes: ClassFilterItem[]
}
export interface ClassFilterData {
  conditions: ClassFilterItem[]
}
export const getClassFilter = (args = {}) => {
  const { partnerId } = getPartner()
  return getRequestWithCache<ClassFilterData>(
    new ApiOptions({
      url: '/net/course/Svc/Mall/GetClassFilter',
      args: {
        organizationId: partnerId,
        ...args,
      },
    }),
  )
}

interface ContactInfoRequest {
  cardType: number
  code?: string | undefined
}
export interface MarketInfoResponse {
  id: number
  outerPlatformId: string
  linkman: string
  phone: string
  qrcode: string
  address: string
  name: string
  logo: string
  teacherName: string
  teacherUserId: number
}
export interface ContactInfoData {
  appQrcode: string
  wxQrcode: string
  marketInfo: MarketInfoResponse
}
export const getContactInfo = (args: ContactInfoRequest) => {
  const { partnerId } = getPartner()
  return getRequestWithCache<ContactInfoData>(
    new ApiOptions({
      url: '/net/course/Svc/Mall/GetContactInfo',
      args: {
        organizationId: partnerId,
        ...args,
      },
    }),
  )
}

export const getStudentPartnerSetting = (settingType: { settingType: number }) => {
  const { partnerId } = getPartner()
  return getRequestWithCache<number>(
    new ApiOptions({
      url: `/net/course/${partnerId}/Student/GetPartnerSettingValue`,
      args: { ...settingType },
    }),
  )
}

export const getSelectedMarketInfo = () => {
  const { partnerId } = getPartner()
  return getRequestWithCache<MarketInfoType>(
    new ApiOptions({
      url: `/net/course/${partnerId}/Trial/GetSelectedMarketingInfo`,
    }),
  )
}

interface SubmitCollectRequest {
  displayName: string
  phone: string
  collectType: string
  formName: string
  principal: string
  producer: string
}
export const submitCollect = (params: SubmitCollectRequest) => {
  const { partnerId, partnerType } = getPartner()
  return postRequest(
    new ApiOptions({
      url: '/net/course/Svc/Information/Collect/Submit',
      args: {
        organizationId: `${partnerType}_${partnerId}`,
        ...params,
      },
    }),
  )
}

interface CourseDetailItem {
  id: number
  classCourseId: number
  type: number
  teacherSchoolId: number
}
interface ClassItem {
  id: number
  courseList: CourseDetailItem[]
}
interface ClassDetailData {
  teachingInQingshu: boolean
  classList: ClassItem[]
  productType: number
  trainingTypeId: number
  parentTrainingTypeId: number
}
export const getClassDetail = (productId: { productId: string }) => {
  const { partnerId } = getPartner()
  return getRequest<ClassDetailData>(
    new ApiOptions({
      url: '/net/course/Svc/Mall/Class/Detail',
      args: { organizationId: partnerId, ...productId },
    }),
  )
}

interface SearchBaseRequest extends PaginationRequest {
  organizationType?: number | null
  trainingTypeId?: number | null
  classId?: number | null
}
interface CourseSearchRequest extends SearchBaseRequest {
  typeList?: number[] | null
  subjectId?: string | null
  year?: string | null
}
interface ClassSearchBaseData {
  classId: number
  classCourseId: number
  teacherSchoolId: number
  name: string
  coverImage: string
  type: number
  courseType: number
  expiredTime: number
  lessonCount: number
  subjectName: string
  studyDuration: number
  courseOriginType: number
}
export interface CourseSearchData extends ClassSearchBaseData {
  id: number
  liveStartTime: number
  liveStatus: number
}
export const getCourseSearch = (params: CourseSearchRequest) => {
  const { partnerId } = getPartner()
  return getRequest<PaginationList<CourseSearchData>>(
    new ApiOptions({
      url: `/net/course/${partnerId}/Student/Mall/Course/Search`,
      args: params,
    }),
  )
}

interface CourseDetailRequest {
  organizationType: number
  courseId: number
  classId: number
  sectionTypeId?: string | null
}
export interface SectionType {
  id: string
  name: string
  iconUrl: string
  type: number
}
export interface QuizItem {
  id: string
  type: number
  name: string
  status: number
  lastStudy: boolean
  objectiveCorrectCount: number
  objectiveCount: number
  objectiveScore: number
  subQuiz: QuizItem[]
  totalScore: number
}
export interface CourseDetailResponse {
  id: number
  name: string
  type: number
  sectionTypeList: SectionType[]
  quizList: QuizItem[]
}
export const getCourseDetail = (params: CourseDetailRequest) => {
  const { partnerId } = getPartner()
  return getRequest<CourseDetailResponse>(
    new ApiOptions({
      url: `/net/course/${partnerId}/Student/Mall/Course/Detail`,
      args: params,
    }),
  )
}

interface StudentClassFilterRequest {
  trainingType?: number | string | null | undefined
  organizationType?: number | null | undefined
  filterType?: number | null | undefined
  typeList?: number[] | null | undefined
}

export interface StudentClassFilterItem extends FilterItem {
  type: number
  subNodes: StudentClassFilterItem[]
}
export const getStudentClassFilter = (params: StudentClassFilterRequest) => {
  const { partnerId } = getPartner()
  return getRequest<{ conditions: StudentClassFilterItem[] }>(
    new ApiOptions({
      url: `/net/course/${partnerId}/Student/Mall/GetStudentClassFilter`,
      args: { ...params, organizationId: partnerId },
    }),
  )
}

interface StudentClassSubjectFilterRequest {
  classId: number
  type: number
  organizationType?: number
  returnCourseWithoutSubject?: boolean
}

interface SubjectCourseFilter extends FilterItem {
  hasExpired: boolean
  type: number
  subNodes: SubjectCourseFilter[]
}
interface SubjectCourseFilterResponse {
  conditions: SubjectCourseFilter[]
}
export const getSubjectCourseFilter = (params: StudentClassSubjectFilterRequest) => {
  const { partnerId } = getPartner()
  return getRequest<SubjectCourseFilterResponse>(
    new ApiOptions({
      url: `/net/course/${partnerId}/Student/Mall/GetSubjectCourseFilter`,
      args: { ...params },
    }),
  )
}

interface AppDownloadInfo {
  cover: string
  customQRCode: number
  description: string
  normalQRCode: number
  partnerId: number
  title: string
  type: number
  hasCustomApp: boolean
}

export const enum AppDownloadQRCodeType {
  NormalQRCode = 1,
  CustomQRCode = 2,
}

export const getAppDownloadInfo = (defaultSetting = false) => {
  const { partnerId } = getPartner()
  return getRequestWithCache<AppDownloadInfo>(
    new ApiOptions({
      url: '/net/course/Svc/Mall/AppDownload/Info',
      args: { organizationId: partnerId, defaultSetting: defaultSetting || false },
    }),
  )
}

export interface DefaultFilterItem {
  organizationType: number
  trainingType?: number
  parentTrainingType?: number
  classId: number
  courseId: number
  subjectId: string
}
export const getDefaultFilterItem = (params: { type: number; organizationType?: number | undefined }) => {
  const { partnerId } = getPartner()
  return getRequest<DefaultFilterItem>(
    new ApiOptions({
      url: `/net/course/${partnerId}/Student/Mall/GetDefaultFilterItem`,
      args: { ...params },
    }),
  )
}

