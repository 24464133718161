/**
 * 转换为驼峰模式
 */
export const toCamelCase = function (str: string) {
  const result = String(str)
    .replace(/[_-][a-zA-Z]/gi, s => s.substring(1).toUpperCase())
    .replace(/^[A-Z]/g, s => s.toLowerCase())
  return result.replace(/(^-*)(.*[^-])(-*$)/, '$2')
}

/**
 * 转换为连字模式
 */
export const toKebabCase = function (str: string) {
  const result = String(str).replace(/[A-Z]/g, (i) => {
    return `-${i.toLowerCase()}`
  })
  return result.replace(/(^-*)(.*[^-])(-*$)/, '$2')
}

export function includeAny(...target: string[]) {
  const str = target.splice(0, 1)[0]
  return target.some(s => (str || '').includes(s))
}

// 处理千分位
export const formatThousandth = (str: string | number) => {
  return String(str).replace(/(\d)(?=(\d{3})+\b)/g, '$1,')
}

// 把html标签替换成转义字符
export const convertHtmlTag = (str: string) => {
  if (!str) {
    return str
  }
  str = str.replace(/</g, '\&lt')
  str = str.replace(/>/g, '\&gt')
  return str
}
