import { RequestError } from '@shared/constants/error'
import { BiLogger } from './base'
import type { BizLog } from './base'

type LogType = Error | ErrorEvent | PromiseRejectionEvent | string[] | string | undefined
// eslint-disable-next-line complexity
function isIgnoreError(error: LogType) {
  return !error
    || (Array.isArray(error) && !error.length)
    || error instanceof RequestError
    || (error instanceof Error && error.message.includes('Request failed') && !error.message.includes('400'))
    || (error instanceof Error && (
      error.message.includes('Unable to preload CSS')
      || error.message.includes('Failed to fetch dynamically imported module')
      || error.message.includes('Importing a module script failed.')
      || error.message.includes('已取消')
      || error.message.includes('Load failed')
      || error.message.includes('Network Error')
      || error.message.includes('chrome-extension://')
    ))
    || (error instanceof Error && (error.name === 'AbortError' || error.name === 'NotAllowedError'))
    || (typeof error === 'string' && error.includes(' media '))
}
function parseError(error: LogType) {
  if (error instanceof PromiseRejectionEvent) {
    error = error.reason
  }
  if (Array.isArray(error) && error.length === 1) {
    error = error[0]
  }
  if (error instanceof ErrorEvent) {
    error = error.error
  }
  if (isIgnoreError(error)) {
    return undefined
  }
  if (typeof error === 'string') {
    return { message: error, errorType: 'string' }
  }
  if (Array.isArray(error)) {
    return { message: error.join('\n'), errorType: 'array' }
  }
  if (error instanceof Error) {
    return {
      errorType: error.name,
      message: error.message,
      stack: error.stack,
    }
  }
  return {
    message: JSON.stringify(error, Object.getOwnPropertyNames(error), 2),
    errorType: typeof error,
  }
}

export interface ErrorBizLog extends BizLog {
  message: string
  errorType?: string
  stack?: string | undefined
}
export abstract class ErrorBiLogger extends BiLogger<ErrorBizLog, LogType> {
  constructor(options?: ConstructorParameters<typeof BiLogger>[0]) {
    super(options)
    window.addEventListener('error', error => this.log(error))
    window.addEventListener('unhandledrejection', error => this.log(error))
    window.addEventListener('QsErrorEvent', error => this.log(error.detail), false)
  }

  processError = (error: LogType) => parseError(error)
  abstract processLog(log: ErrorBizLog): ErrorBizLog

  override process = (error: LogType) => {
    const errorInfo = this.processError(error)
    if (errorInfo == null || isIgnoreError(JSON.stringify(errorInfo))) {
      return
    }
    const errorLog: ErrorBizLog = {
      ...errorInfo,
      biz: 'default',
      level: 'error',
      pageUrl: window.location.href,
    }
    return this.processLog(errorLog)
  }
}
