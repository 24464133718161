import { BiLogger, type BizLog } from './base'

export interface PageViewBizLog extends BizLog {
  action: 'page-view'
  referPage?: string | undefined
}
type LogType = Pick<PageViewBizLog, 'biz' | 'appName' | 'pageName' | 'referPage'>
export abstract class PageViewBiLogger extends BiLogger<PageViewBizLog, LogType> {
  processLog = (log: PageViewBizLog) => log
  override process(log: LogType) {
    const viewLog = log as PageViewBizLog
    viewLog.level = 'info'
    viewLog.action = 'page-view'
    return this.processLog(viewLog)
  }
}
