import type { CookieAttributes } from 'js-cookie'
import Cookie from 'js-cookie'

const tokenName = 'AccessToken'
let domain = '.qingshuxuetang.com'
const localDomain = 'localhost'

const getCookie = (name: string) => Cookie.get(name)
const setCookie = (
  name: string,
  value: string,
  option?: CookieAttributes,
): void => {
  Cookie.set(name, value, { domain, ...(option || {}) })
  if (location.host?.includes(localDomain)) {
    Cookie.set(name, value, { domain: location.host, ...(option || {}) })
  }
  Cookie.set(name, value, {
    domain: location.host.replace(/[^.]+/, ''),
    ...(option || {}),
  })
}
const removeCookie = (name: string) => {
  Cookie.remove(name)
  Cookie.remove(name, { domain })
  Cookie.remove(name, { domain: location.host })
  Cookie.remove(name, { domain: location.host.replace(/[^.]+/, '') })
}

const getToken = () => getCookie(tokenName)
const setToken = (value: string) => setCookie(tokenName, value)
const removeToken = () => removeCookie(tokenName)

const setDomain = (newDomain: string) => {
  domain = newDomain
}
export { setDomain, getCookie, setCookie, removeCookie, getToken, setToken, removeToken }
