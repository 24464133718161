export const DEFAULT_ROUTES = [
  {
    path: '/:partnerType([A-Za-z]+)/:partnerId([0-9]+)/notFound',
    name: 'notFoundSpecify',
    component: () => import('../views/NotFound.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('../views/NotFound.vue'),
  },
]
