import type { Module } from '@common/api/px/user'

export function getModule(modules: Module[] = [], id: number) {
  let res = null as Module | null
  for (const module of modules) {
    if (id === module.id) {
      return module
    }
    if (module.children?.length) {
      res = getModule(module.children, id) as Module | null
      if (res) {
        return res
      }
    }
  }
  return res
}

export function getLeafModule(module: Module) {
  if (module?.children?.length) {
    return getLeafModule(module.children[0]!)
  }
  return module
}

export function isModuleActionAllowed(
  modules: Module[],
  moduleId: number,
  ...actions: (string | undefined)[]
) {
  const currentModule = getModule(modules, moduleId)
  actions = actions.filter(item => !!item)
  return (
    !!currentModule
    && (!actions.length
      || !currentModule.actions
      || !!currentModule.actions.find(item => actions.includes(item.action) && item.allowed))
  )
}
