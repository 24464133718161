export function parseBoolean(value, defaultValue = false) {
  if (value === undefined) {
    return defaultValue
  }
  if (typeof value === 'string') {
    if (value === 'true') {
      return true
    } else if (value === 'false') {
      return false
    }
  }
  return !!value
}
