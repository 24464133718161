import { ApiOptions, getRequestWithCache } from '../index'
import { getPartner } from '../../router/index'

export interface CustomDomainResponse {
  domainHost: string | null
  tpDomainHost: string | null
  type: number
  publicSecurityCode: string
  icpCode: string
}
export interface PermissionItem {
  key: string
  value: string
}

export interface PreventStealSetting {
  preventSteal: boolean
  showWatermark: boolean
  forbidDownloading: boolean
  forbidRecording: boolean
  watermarkSize: number
  watermarkOpacity: number
}
export interface OrganizationDetailResponse {
  styleSetting: {
    type: number
    color: number
  }
  id: number
  name: string
  type: string
  logo: string
  watermarkUrl: string
  partnerWatermarkUrl: string
  partnerWatermarkPosition: number
  status: number
  isTrial: boolean
  trialEndTime: number
  isMallDisplay: boolean
  isSalesDisplay: boolean
  isStudentExpiredTimeDisplay: boolean
  isNewAccessControlSystem: boolean
  address: string
  admin: string
  email: string
  description: string
  permissions: PermissionItem[]
  customDomain: CustomDomainResponse
  allowAsk: boolean
  allowComment: boolean
  preventStealSetting: PreventStealSetting
  trial: boolean
  turnToLoginPage: boolean
  loginPageUrl: string
}
export const getOrganizationDetail = (params?: { force: boolean }) => {
  const { partnerId } = getPartner()
  return getRequestWithCache<OrganizationDetailResponse>(
    new ApiOptions({
      url: '/net/api/organization/detail',
      args: { organizationId: partnerId },
    }),
    { force: params?.force || false },
  )
}
