export class NoPermissionError extends Error {}
export class NoWritePermissionError extends Error {}
export class NoModulePermissionError extends Error {}
export class NoCoursePermissionError extends Error {}
export class NoActivePermissionError extends Error {}
export class TeachingPermissionError extends Error {}
export class RedirectError extends Error {
  constructor(route, url) {
    super()
    this.route = route
    this.url = url
  }
}

export class RequestError extends Error {
  constructor(e) {
    super(e)
    this.hr = e.hr
    this.data = e.data
    if (e.message === 'Network Error' || e.message?.includes('timeout of')) {
      this.message = '网络错误'
    } else if (e.message?.includes('code 404')) {
      this.message = '服务器开小差啦'
    } else if (
      e.message?.includes('code 401')
      || e.message?.includes('code 302')
      || e.message?.includes('code 403')
    ) {
      const code = e.message?.match(/(401|302|403)/)?.[1] || ''
      this.message = code ? `[${code}] 服务器开小差啦` : '服务器开小差啦'
    } else {
      this.message = e.message
    }
  }
}

export class CustomError extends Error {
  constructor(e) {
    super(e)
    this.value = e.value
  }
}
