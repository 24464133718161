export const PARTNER_SETTING = Object.freeze({
  OPEN_COURSE_STUDENT_WITH_SMS: 10,
  CONTINUE_CLASS_STUDENT_WITH_SMS: 11,
  COURSE_ALLOW_ASK: 16,
  COURSE_ALLOW_COMMENT: 17,
  YXK_SHOW_SHARE_TIP: 21,
  MALL_DISPLAY: 22,
  INFO_COLLECT_TEACHER_CAN_ASSIGN: 23,
  INFO_COLLECT_AUTO_RETURN: 24,
  INFO_COLLECT_TEACHER_CAN_RETURN: 25,
  SALES_DISPLAY: 26,
  EXPIRE_TIME_DISPLAY: 27,
})

export const PARTNER_SETTING_VALUE = Object.freeze({
  Close: 0,
  Open: 1,
})

export const SHARE_TYPE = Object.freeze({
  YXK: 'YXK',
  OrganizationCourse: 'OrganizationCourse',
  Mall: 'Mall',
  Activity: 'Activity',
  Course: 'Course',
})

export const CARD_TYPE = Object.freeze({
  None: 0,
  Org: 1,
  Mine: 2,
  More: 3,
})

export const SHARE_POSTER_TYPE = Object.freeze({
  Mall: 1,
  Course: 2,
  Coupon: 3,
  Activity: 4,
})

export const GEN_CODE_TYPE = Object.freeze({
  None: 0,
  Mall: 1,
  Course: 2,
  Activity: 3,
})

export const WX_CODE_TYPE = Object.freeze({
  Yxk: 27,
  Mall: 30,
  BusinessCard: 32,
  Org: 35,
  Coupon: 39,
  Activity: 41,
  Distribution: 42,
  DistributionMall: 43,
  OpenClass: 45,
  Questionnaire: 46,
  YxkTrial: 47,
})

export const YXK_SHARE_TIP = Object.freeze({
  close: { label: '未开启', value: 0 },
  open: { label: '开启', value: 1 },
})

