export const getQuery = (query) => {
  const result = []
  for (const key in query) {
    if (query[key] != null) {
      result.push(`${key}=${query[key]}`)
    } else if (query[key] === null) {
      result.push(`${key}`)
    }
  }
  return result.join('&')
}

export const parseQuery = (query) => {
  const res = {}
  if (query === undefined) {
    return res
  }
  query = query.trim().replace(/^(\?|#|&)/, '')
  if (!query) {
    return res
  }
  query.split('&').forEach((param) => {
    const parts = param.replace(/\+/g, ' ').split('=')
    const key = decodeURIComponent(parts.shift())
    const val = parts.length > 0 ? decodeURIComponent(parts.join('')) : null
    if (res[key] === undefined) {
      res[key] = val
    } else if (Array.isArray(res[key])) {
      res[key].push(val)
    } else {
      res[key] = [res[key], val]
    }
  })
  return res
}

export function getQueryParam(name, notUnescape) {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`)
  const r = window.location.search.substr(1).match(reg)
  if (r != null) {
    return notUnescape ? r[2] : unescape(r[2])
  }
  return null
}

export function replaceUrlParam(url, paramName, paramValue) {
  if (paramValue == null) {
    paramValue = ''
  }
  const pattern = new RegExp(`\\b(${paramName}=).*?(&|#|$)`)
  if (url.search(pattern) >= 0) {
    return url.replace(pattern, `$1${paramValue}$2`)
  }
  url = url.replace(/[?#]$/, '')
  return `${url + (url.indexOf('?') > 0 ? '&' : '?') + paramName}=${paramValue}`
}

/**
 *
 * @param {string} source
 * @param {string} target
 * @param {object} excludeMap
 * @returns
 */
export function isUrlDifferentWithExclude(source, target, excludeMap = {}) {
  const [sourcePath, sourceQuery] = source.split('?')
  const [targetPath, targetQuery] = target.split('?')
  if (sourcePath !== targetPath) {
    return true
  }
  const sourceQueryArr = (sourceQuery?.split('&') || []).sort()
  const targetQueryArr = (targetQuery?.split('&') || []).sort()
  const sourceQueryStr = sourceQueryArr.filter(v => !(v.split('=')[0] in excludeMap)).join('&')
  const targetQueryStr = targetQueryArr.filter(v => !(v.split('=')[0] in excludeMap)).join('&')
  return sourceQueryStr !== targetQueryStr
}

export const formatUrlEncode = (value) => {
  try {
    const decodedValue = decodeURI(value)
    // 如果解码后的值与原值相同，说明原值没有被编码过
    return value === decodedValue ? encodeURI(value) : value
  } catch (e) {
    // 如果解码出错，说明原值没有被编码过
    return encodeURI(value)
  }
}
