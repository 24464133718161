import { RequestHeader } from '@shared/constants/sessionKeys'
import type { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from 'axios'
import axios from 'axios'
import Qs from 'qs'
import { CLIENT_TYPE } from '../../constants/client'
import { RequestError } from '../../constants/error'
import { QsLruExpireCache } from '../cache/lruCache'
import { getToken } from '../cookie'
import { MINUTE_IN_MS, setNow } from '../date'
import NProgress from '../nprogress'
import { getPromise } from '../promise'
import type { Nullable } from '../types'
import { delay } from '../delay'
import type { BaseApiOptions } from './options'
import { isGetMethod } from './options'
import type { ApiCacheOptions, QsAxiosRequestConfig, QsAxiosResponseHeaders, QsHrResponse } from './types'
import { limitTraffic } from './plugins/trafficLimit'

export const USER_ID_KEY = 'user-id-qs'
export const ANONYMOUS_TOKEN_KEY = 'anonymous-token'
const axiosInstance = axios.create({ headers: {} })
const registerAxiosInterceptors = (
  errorHandle?: (errorResponse: AxiosResponse) => void | Promise<any>,
  exceptionHandle?: (err: AxiosError) => void,
) => {
  axiosInstance.interceptors.request.use(
    (config: QsAxiosRequestConfig) => {
      config.qsArg?.progress && NProgress.start()
      Object.entries({
        'Authorization-QS': getToken() || localStorage.getItem(ANONYMOUS_TOKEN_KEY),
        'X-Requested-With': 'XMLHttpRequest',
        'User-Agent-QS': sessionStorage.getItem(RequestHeader.UserAgentQS) ?? 'PC',
        'Device-Info-QS': sessionStorage.getItem(RequestHeader.DeviceInfoQS) ?? JSON.stringify({ clientType: CLIENT_TYPE.PcWeb }),
      }).filter(([_, v]) => v).forEach(([k, v]) => config.headers![k] = v!)
      return config
    },
    error => Promise.reject(error),
  )
  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      const headers = (response.headers as QsAxiosResponseHeaders)
      setNow(headers.date ? new Date(headers.date).getTime() : Date.now())
      sessionStorage.removeItem(USER_ID_KEY)
      getToken() && sessionStorage.setItem(USER_ID_KEY, `${headers[USER_ID_KEY] ?? ''}`)
      ;(response.config as QsAxiosRequestConfig).qsArg?.progress && NProgress.done()
      return response
    },
    (error: AxiosError) => {
      if (error.response) {
        const handleResult = errorHandle && errorHandle(error.response)
        if (handleResult instanceof Promise) {
          return Promise.reject(handleResult)
        }
      } else {
        exceptionHandle && exceptionHandle(error)
      }
      return Promise.reject(error)
    },
  )
}

const requestPromises = new Map<string, Promise<any>>()
const request = <T>(method: Method | AxiosRequestConfig, options: BaseApiOptions) => {
  let requestConfig: AxiosRequestConfig
  if (typeof method === 'object') {
    requestConfig = method
  } else {
    requestConfig = {
      qsArg: options,
      baseURL: options.baseUrl,
      url: options.url,
      method,
      data: !isGetMethod(method) ? options.args : null,
      params: isGetMethod(method) ? options.args : null,
      timeout: options.timeout,
      paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'brackets' }),
    } as QsAxiosRequestConfig
  }
  const key = JSON.stringify(requestConfig)
  if ((options.needCache ?? isGetMethod(requestConfig.method)) && requestPromises.has(key)) {
    return requestPromises.get(key) as Promise<Nullable<QsHrResponse<T>>>
  }
  const { promise, resolve, reject } = getPromise()
  requestPromises.set(key, promise)
  axiosInstance
    .request<Nullable<QsHrResponse<T>>>(requestConfig)
    .then((response) => {
      if (response.data?.hr === 0) {
        return resolve(response.data)
      }
      return Promise.reject(response.data)
    })
    .catch((e) => {
      if (e instanceof Promise) {
        return e.then(res => resolve(res)).catch(e => reject(e))
      }
      return reject(new RequestError(e))
    })
  return (promise as Promise<Nullable<QsHrResponse<T>>>)
    .finally(() => delay(0).then(() => requestPromises.delete(key)))
}
const limitRequest = limitTraffic<typeof request>(request, 5) as unknown as typeof request

const DEFAULT_CACHE = new QsLruExpireCache(undefined, {
  limit: 20,
  defaultExpire: 2 * MINUTE_IN_MS,
})
const requestWithCache = <T>(
  method: Method,
  options: BaseApiOptions,
  cacheOptions: ApiCacheOptions = {},
): Promise<Nullable<QsHrResponse<T>>> => {
  const {
    cache = DEFAULT_CACHE,
    key = [options.url, Qs.stringify(options.args || {})].join('?'),
    expireTime,
    force,
  } = cacheOptions
  const resCached = cache.get(key)
  if (force || !cache.has(key)) {
    return limitRequest(method, options).then((res) => {
      cache.set(key, res, expireTime)
      return JSON.parse(JSON.stringify(res))
    })
  }
  return Promise.resolve(JSON.parse(JSON.stringify(resCached)))
}
const limitRequestWithCache
  = limitTraffic<typeof requestWithCache>(requestWithCache, 5) as unknown as typeof requestWithCache

function genRequest(
  ApiOptions: typeof BaseApiOptions,
  errorHandle?: (errorResponse: AxiosResponse) => void | Promise<any>,
  exceptionHandle?: (err: AxiosError) => void,
) {
  registerAxiosInterceptors(errorHandle, exceptionHandle)

  const getOption = (options: BaseApiOptions | string) =>
    typeof options === 'string' ? new ApiOptions({ url: options }) : options
  const generateRequestMethod = (method: Method) => {
    return <T>(options: BaseApiOptions | string) => limitRequest<T>(method, getOption(options))
  }
  const generateRequestWithCacheMethod
    = (method: Method) => <T>(options: BaseApiOptions | string, cacheOptions: ApiCacheOptions = {}) => {
      return limitRequestWithCache<T>(method, getOption(options), cacheOptions)
    }

  return {
    request: limitRequest,
    requestWithCache: limitRequestWithCache,
    getRequest: generateRequestMethod('get'),
    postRequest: generateRequestMethod('post'),
    getRequestWithCache: generateRequestWithCacheMethod('get'),
    postRequestWithCache: generateRequestWithCacheMethod('post'),
    clearRequestCache: () => DEFAULT_CACHE.clear(),
  }
}

export * from './options'
export * from './types'
export { genRequest }
