import { NoPermissionError, RedirectError } from '@shared/constants/error'
import { LOGIN_TYPE, SSN_TYPE } from '@shared/constants/user'
import { getToken, removeToken, setToken } from '@shared/util/cookie'
import type { RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import { useNetSchoolStore } from '@common/store/netSchool'
import { getPartner } from '@common/router/index'
import { useUserStore } from '@common/store/user'
import { EMBED_LOGIN_TIME, isEmbed } from '@common/util/embed.client'
import { getLoginUrl } from './util.redirect'
import { getUserIdentity, validUser } from '@/api/px/user'

import { login } from '@/api/px/auth'

export type ModuleRoute = Record<number | string | symbol, RouteRecordRaw>
export const mixinParams = (
  moduleMap: ModuleRoute,
  { moduleName }: { moduleName: string },
) => {
  Object.values(moduleMap).forEach(module => (module.meta!.params = { module: moduleName }))
}

export const setTokenFromRoute = async (to: RouteLocationNormalized) => {
  if (to.query['identity'] === null) {
    removeToken()
  } else if (to.query['identity']) {
    const identity = await getUserIdentity(to.query['identity'] as string)
    if (identity.data) {
      removeToken()
      setToken(identity.data)
      isEmbed() && localStorage.setItem(EMBED_LOGIN_TIME, `${Date.now()}`)
    }
  } else if (to.meta.loginWithSsnToken && !getToken() && to.query['code']) {
    const identity = await login({
      ssnToken: to.query['code'],
      type: LOGIN_TYPE.SsnLogin,
      ssnType: SSN_TYPE.WX_ACCOUNT,
    })
    setToken(identity.data?.token)
  }
  return getToken() ? Promise.resolve() : useUserStore().createAnonymous()
}

export const validToken = (to: RouteLocationNormalized) => {
  if (!to.meta.requiresAuth) {
    return Promise.resolve()
  }
  return getToken() ? Promise.resolve() : Promise.reject(new RedirectError(null, getLoginUrl(to)))
}

export const validPlatformIsClose = (to: RouteLocationNormalized) => {
  if (!getPartner(to).partnerId) {
    return Promise.resolve()
  }
  return useNetSchoolStore()
    .getInfo()
    .then((res) => {
      if (!res.data?.valid && to.name !== 'PlatformClose') {
        throw new RedirectError({ name: 'PlatformClose', params: to.params })
      }
    })
}
/**
 * 手机号、密码等403校验
 */
export const validPassword = (to: RouteLocationNormalized) => {
  if (getToken() && (!to.meta.ignoreUserValid && !isEmbed())) {
    // 通过 api 状态码监听进行页面跳转
    return validPlatformIsClose(to).then(() => validUser().catch(() => {}))
  }
  return Promise.resolve()
}

/**
 * 用户角色校验
 * @usage meta: { roleAnonymous: true }
 * @usage meta: { requiresAuth: true, roleManager: true }
 */
export const validRole = (to: RouteLocationNormalized) => {
  if (to.meta.roleAnonymous) {
    return getToken()
      ? Promise.reject(
        to.meta.distributor // 分销商角色校验
          ? new RedirectError({
            name: 'ChooseOrganization',
            params: { ...to.params, ...getPartner() },
          })
          : new NoPermissionError(),
      )
      : Promise.resolve()
  }
  if (!to.meta.requiresAuth) {
    return Promise.resolve()
  }
  if (to.meta.roleManager) {
    const { partnerId } = getPartner(to)
    return useUserStore().getTeacherOrganization(partnerId).length > 0
      ? Promise.resolve()
      : Promise.reject(new NoPermissionError())
  }
  return Promise.resolve()
}

/**
 * 基本信息路由预获取，用户信息、学校信息
 * @usage meta: { mallBaseInfo: true } 用户信息、学校信息
 * @usage meta: {mallBaseInfo: { user: true, school: true }} 用户信息、学校信息
 */
export const getBaseInfo = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  { isClient } = { isClient: true },
) => {
  if (to.path === from.path) {
    return Promise.resolve()
  }
  const schoolStore = useNetSchoolStore()
  const userStore = useUserStore()
  const requests = []
  if (to.meta.requiresAuth) {
    requests.push(userStore.getUserInfo().catch(() => {}))
  }
  if (to.meta.mallBaseInfo) {
    let needUser = true
    let needSchool = true
    if (typeof to.meta.mallBaseInfo === 'object') {
      needUser = !!to.meta.mallBaseInfo.user
      needSchool = !!to.meta.mallBaseInfo.school
    }
    if (getToken()) {
      if (needUser) {
        requests.push(userStore.getUserInfo().catch(() => {}))
      }
    }
    if (needSchool) {
      requests.push(schoolStore.getInfo().catch(() => {}))
      isClient && requests.push(schoolStore.getModuleSetting().catch(() => {}))
    }
  }
  return Promise.all(requests)
}

export const getModuleInfo = (
  to: RouteLocationNormalized,
  _: RouteLocationNormalized,
  role: 'Teacher' | 'Student',
) => {
  if (!to.meta.requiresAuth || !(to.meta.roleManager || to.meta.roleStudent)) {
    return Promise.resolve()
  }
  const user = useUserStore()
  return user
    .getUserModuleInfo(role)
    .then(() => {
      if (!(to.meta.modulePath || []).every(id => user.hasTeacherModule(id))
      || (to.meta['tabId'] && !user.hasTeacherModule(to.meta['tabId'] as number))) {
        throw new RedirectError({
          name: 'moduleRootRoute',
          params: { ...to.params },
          query: { tabId: to.meta['tabId'] },
        })
      }
    })
}

export const validMetaPermission = (
  to: RouteLocationNormalized,
  _: RouteLocationNormalized,
  { defaultPermissionFn } = { defaultPermissionFn: () => Promise.resolve() },
) => {
  return typeof to.meta.permissionValid === 'function' ? to.meta.permissionValid(to) : defaultPermissionFn()
}
