type Method =
  | 'get' | 'GET'
  | 'delete' | 'DELETE'
  | 'head' | 'HEAD'
  | 'options' | 'OPTIONS'
  | 'post' | 'POST'
  | 'put' | 'PUT'
  | 'patch' | 'PATCH'
  | 'purge' | 'PURGE'
  | 'link' | 'LINK'
  | 'unlink' | 'UNLINK'
const isGetMethod = (method?: Method) => method?.toLowerCase() === 'get'

export interface BaseApiOptionParams {
  method?: Method
  url: string
  args?: object
  /**
   * 超时时间，默认10000ms
   * @default 10000
   */
  timeout?: number
  /**
   * 是否需要进度条，默认false
   * @default false
   */
  progress?: boolean
  /**
   * 是否模拟接口，默认false
   * @default false
   */
  mock?: boolean
  ignore403?: boolean
  needCache?: boolean | undefined
}
class BaseApiOptions implements BaseApiOptionParams {
  method: Method
  url: string
  args: object
  timeout: number
  progress: boolean
  mock: boolean
  ignore403: boolean
  needCache?: boolean | undefined
  [key: string]: any

  constructor(
    { method, url, args, timeout, progress, mock, ignore403, needCache }: BaseApiOptionParams,
  ) {
    this.method = method || 'get'
    this.url = url
    this.args = args ?? {}
    this.timeout = timeout ?? 10000
    this.progress = !!progress
    this.mock = !!mock
    this.ignore403 = !!ignore403
    this.needCache = needCache
  }

  get baseUrl() {
    return ''
  }
}

export { BaseApiOptions, isGetMethod }
