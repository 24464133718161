import { getPartner } from '@common/router/index'
import { ApiOptions, postRequest } from '@common/api/index'

interface Validation {
  type: number
  sessionId: string
  userInput: string
}

interface LoginRequest {
  name?: string
  password?: string
  phone?: string
  type?: number
  ssnType?: number
  ssnToken?: string
  validation?: Validation | null
}
interface LoginResponse {
  token: string
  pwdStrong: boolean
}
export const login = (params: LoginRequest) => {
  const { partnerId } = getPartner()
  return postRequest<LoginResponse>(
    new ApiOptions({
      url: `/net/course/Open/Svc/${partnerId}/Login`,
      args: params,
    }),
  )
}

export const bindWXLogin = (params: LoginRequest) => {
  return postRequest<LoginResponse>(
    new ApiOptions({
      url: '/net/course/Open/Svc/Login',
      args: params,
    }),
  )
}

interface RegisterRequest {
  phone: string
  password: string
  validation?: Validation | null
}
export const register = (params: RegisterRequest) => {
  const { partnerId: organizationId } = getPartner()
  return postRequest<{ token: string }>(
    new ApiOptions({
      url: '/net/course/Open/Svc/Register',
      args: { ...params, organizationId },
    }),
  )
}

interface RegisterAndLoginRequest {
  phone: string
  validation: Validation
}
export const registerAndLogin = (params: RegisterAndLoginRequest) => {
  const { partnerId: organizationId } = getPartner()
  return postRequest<{ token: string }>(
    new ApiOptions({
      url: '/net/course/Open/Svc/RegisterAndLogin',
      args: { ...params, organizationId },
    }),
  )
}
