export function isDefined(value) {
  return value != null
}

export function isElement(value) {
  return (
    value instanceof Element
    || (typeof HTMLDocument !== 'undefined' && value instanceof HTMLDocument)
  )
}

export function isFunction(value) {
  return typeof value === 'function'
}

export function isObject(obj) {
  return Object.prototype.toString.call(obj).toLowerCase() === '[object object]'
}

export function getTag(value) {
  if (value == null) {
    return value === undefined ? '[object Undefined]' : '[object Null]'
  }
  return Object.prototype.toString.call(value)
}

export function isObjectLike(value) {
  return typeof value === 'object' && value != null
}

export function isPlainObject(value) {
  if (!isObjectLike(value) || getTag(value) !== '[object Object]') {
    return false
  }
  if (Object.getPrototypeOf(value) === null) {
    return true
  }
  let proto = value
  while (Object.getPrototypeOf(proto) !== null) {
    proto = Object.getPrototypeOf(proto)
  }
  return Object.getPrototypeOf(value) === proto
}

export function isEmptyArray(arr) {
  return Array.isArray(arr) && arr.length === 0
}
