/* eslint-disable prefer-rest-params */
/* eslint-disable no-extend-native */
if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function (regExp, target) {
    if (Object.prototype.toString.call(regExp) === '[object RegExp]') {
      return String.prototype.replace.apply(this, arguments)
    } else {
      return this.replace(new RegExp(regExp, 'g'), target)
    }
  }
}

if (!Array.prototype.flat) {
  Object.defineProperty(Array.prototype, 'flat', {
    configurable: true,
    value: function flat() {
      const depth = isNaN(arguments[0]) ? 1 : Number(arguments[0])

      return depth > 0
        ? Array.prototype.reduce.call(this, (acc, cur) => {
          if (Array.isArray(cur)) {
            // eslint-disable-next-line prefer-spread
            acc.push.apply(acc, flat.call(cur, depth - 1))
          } else {
            acc.push(cur)
          }

          return acc
        }, [])
        : Array.prototype.slice.call(this)
    },
    writable: true,
  })
}
if (!Array.prototype.flatMap) {
  Object.defineProperty(Array.prototype, 'flatMap', {
    configurable: true,
    value: function flatMap() {
      return Array.prototype.map.apply(this, arguments).flat()
    },
    writable: true,
  })
}
