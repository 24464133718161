import { ApiOptions, getRequest } from '../index'

export const refreshToken = () => {
  return getRequest(
    new ApiOptions({
      url: '/net/course/Open/Svc/RefreshToken',
    }),
  )
}

