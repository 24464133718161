import { getPartner } from '@common/router/index'
import { ApiOptions, getRequest, postRequest } from '@common/api/index'

export const validUser = () => {
  const { partnerId } = getPartner()
  return getRequest(
    new ApiOptions({
      url: `/net/course/${partnerId}/User/Valid`,
    }),
  )
}

interface UnbindThirdPartyRequest {
  password: string
  type: number
}

export const unbindThirdParty = (params: UnbindThirdPartyRequest) => {
  return postRequest(
    new ApiOptions({
      url: '/net/course/Account/UnbindThirdParty',
      args: params,
    }),
  )
}

export const setUserIdentity = () => {
  return postRequest<string>(
    new ApiOptions({
      url: '/net/course/User/Identity/Set',
    }),
  )
}
export const getUserIdentity = (key: string) => {
  return getRequest<string>(
    new ApiOptions({
      url: '/net/course/User/Identity/Get',
      args: { key },
    }),
  )
}

