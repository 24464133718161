import { defineStore } from 'pinia'
import { genUuid } from '@shared/util/uid'
import { useStorage } from '@vueuse/core'

export const useDeviceStore = defineStore({
  id: 'device',
  state: () => ({
    fingerprint: useStorage('user-device', `mall_web_${genUuid()}`),
  }),
})
