import { getPartner } from '@common/router/index'
import { ApiOptions, getRequest, getRequestWithCache, postRequest } from '../../index'

interface PurchaseRequest {
  orderId?: number | null
  couponId?: number | null
  schoolId: number
  organizationId: number
  offerIds: string[]
  payType: number
  specificPayType: number
  receipt?: string | null
  producerId?: number | null
  shareUserId?: number | null
  receivers?: number[] | null
  activityId?: number | null
  distributorId?: number | null
  hideContact?: boolean | null
}
export interface PurchaseResult {
  token: string
  purchaseId: number
  status: number
  message: string

  prePayResponseInfo: {
    partnerId: number
    appId: string
    nonceStr: string
    packageStr: string
    timestamp: string
    sign: string
    signType: string
    mwebUrl: string
    codeUrl: string
    alipayPageBody: string
  }
}
export const classPurchase = (params: PurchaseRequest) => {
  const { partnerId } = getPartner()
  return postRequest<PurchaseResult>(
    new ApiOptions({
      url: `/net/course/${partnerId}/Student/Class/Purchase`,
      args: params,
    }),
  )
}

export interface PaymentSetting {
  appId: string
  redirectUri: string
}
export const getPaymentSetting = (force = false) => {
  return getRequestWithCache<PaymentSetting>(
    new ApiOptions({
      url: '/net/course/Svc/Partner/PaymentSetting',
    }),
    { force },
  )
}

export const getAllowPayTypes = () => {
  const { partnerId } = getPartner()
  return getRequest<number[]>(
    new ApiOptions({
      url: `/net/course/${partnerId}/Purchase/GetAllowPayTypes`,
    }),
  )
}
