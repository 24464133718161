import { getPromise } from '@shared/util/promise'

export function limitTraffic<FN extends (...args: any) => any>(requestFn: FN, limit = 10) {
  let requestingCount = 0
  const requestPool: (() => unknown)[] = []
  return (...args: Parameters<FN>) => {
    const { promise, resolve } = getPromise<ReturnType<FN>>()
    const request = () => {
      ++requestingCount
      resolve(requestFn.apply(requestFn, args).finally(() => {
        --requestingCount
        requestPool.shift()?.()
      }))
    }
    if (requestingCount < limit) {
      request()
    } else {
      requestPool.push(request)
    }
    return promise
  }
}
